import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import {
  ADSAnalyticsGroupTypeEntity,
  ADSAnalyticsResponse,
} from "@services/creative-analytics"
import { metricsDescriptors } from "@framework/constants/metrics"
import Typography from "@components/ui/Typography/Typography"
import { printOnlyDateRange } from "@utils/date"
import get from "lodash/get"
import {
  MetricDescription,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import MetricCard from "@pages/MetaAdsChannel/components/ReportDetails/MetricCard"
import NoData from "@components/ui/NoData/NoData"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import ReportCard from "@pages/MetaAdsChannel/components/ReportCard/ReportCard"
import { MetaCampaignStatus } from "@pages/MetaAdsChannel/types"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import { ButtonGroup } from "@components/ui/Button"
import Table from "@pages/MetaAdsChannel/components/Table/Table"
import mapper from "@pages/MetaAdsChannel/General/mapper"
import styles from "./GroupsSidebar.module.scss"

interface GroupsSidebarProps {
  isOpen: boolean
  onClose: () => void
  activeGroupAds: AnalyticsCard[]
  activeGroup: ADSAnalyticsGroupTypeEntity | null
  period: DateRangeOption
  adsCardsData: ADSAnalyticsResponse["data"]
  metricsTrend: MetricsTrendType
  activeMetrics: MetricDescription[]
  onOpenDetailsModal: (ad: AnalyticsCard) => void
  onOpenKPItrendModal: (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => void
  onVideoPreviewOpen: (ad: AnalyticsCard) => void
}

const filteredMetricsDescriptors = metricsDescriptors.filter(
  (md) => md.name === "Impressions" || md.name === "Clicks"
)

const GroupsSidebar: FC<GroupsSidebarProps> = observer(
  ({
    isOpen,
    onClose,
    activeGroupAds,
    activeGroup,
    period,
    metricsTrend,
    adsCardsData,
    activeMetrics,
    onOpenDetailsModal,
    onOpenKPItrendModal,
    onVideoPreviewOpen,
  }) => {
    const [view, setView] = useState<"card" | "table">("card")

    const handleOpenVideoPreview = (ad: AnalyticsCard) => {
      onVideoPreviewOpen(ad)
    }

    return (
      <SidebarContainer isOpen={isOpen} onClose={onClose}>
        <aside className={styles.GroupsSidebar}>
          <Typography weight="bold" className={styles.title} type="h2">
            {activeGroup?.Name}
          </Typography>
          <Typography weight="bold" type="h3">
            {printOnlyDateRange(period?.range)}
          </Typography>
          <Typography weight="bold" type="h4" className={styles.titleAds}>
            Number of Ads: {activeGroupAds.length}
          </Typography>

          <div className={styles.metricsBar}>
            <div className={styles.metricCards}>
              {filteredMetricsDescriptors.map((metric) => {
                const value = get(
                  activeGroup?.Performance,
                  metric.name,
                  null
                ) as MetricType | null
                return (
                  <MetricCard
                    className={styles.metricCard}
                    key={metric.name}
                    value={value}
                    metric={metric}
                    metricsTrend={metricsTrend}
                  />
                )
              }) ?? <NoData />}
            </div>
            <ButtonGroup>
              <IconButton
                variant="contained"
                color={view === "card" ? "primary" : "secondary"}
                onClick={() => {
                  setView("card")
                }}
              >
                <Icon name="dashboard1" />
              </IconButton>
              <IconButton
                variant="contained"
                color={view === "table" ? "primary" : "secondary"}
                onClick={() => {
                  setView("table")
                }}
              >
                <Icon name="menu" />
              </IconButton>
            </ButtonGroup>
          </div>

          <Typography weight="bold" className={styles.adsListTitle} type="h3">
            Top Ads
          </Typography>
          {view === "card" && (
            <div className={styles.adsList}>
              {activeGroupAds.map((ad) => (
                <ReportCard
                  onOpenVideoPreview={() => handleOpenVideoPreview(ad)}
                  data={ad}
                  metricsTrend={metricsTrend}
                  metrics={activeMetrics}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    onOpenDetailsModal(ad)
                  }}
                  onOpenKPIChart={onOpenKPItrendModal}
                  key={ad.Id}
                />
              ))}
            </div>
          )}

          <div>
            {view === "table" && (
              <Table
                className={styles.table}
                mapper={Object.values(mapper(activeMetrics, metricsTrend))}
                data={activeGroupAds}
                metricsTrend={metricsTrend}
                onRowClick={(card) => {
                  onOpenDetailsModal(card)
                }}
              />
            )}
          </div>

          {/* {videoPreviewModal.isOpened && adDetails ? ( */}
          {/*  <VideoPreviewModal */}
          {/*    positionLeft */}
          {/*    data={adDetails} */}
          {/*    onDetailsOpen={() => { */}
          {/*      onOpenDetailsModal(adDetails) */}
          {/*      videoPreviewModal.setOpened(false) */}
          {/*    }} */}
          {/*    onClose={() => videoPreviewModal.setOpened(false)} */}
          {/*  /> */}
          {/* ) : null} */}
        </aside>
      </SidebarContainer>
    )
  }
)

export default GroupsSidebar
