import React, { FC } from "react"
import clsx from "clsx"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"

import styles from "./AdsImages.module.scss"

interface AdsImagesProps {
  analyticsCard: AnalyticsCard[]
  deleteSelectedProduct: (id: string) => void
  hoveredAddIndex: number | null
}

const AdsImages: FC<AdsImagesProps> = ({
  analyticsCard,
  deleteSelectedProduct,
  hoveredAddIndex,
}) => {
  const truncateName = (str: string): string =>
    str.length > 27 ? `${str.slice(0, 27)}...` : str

  return (
    <div className={styles.productsContainer}>
      {analyticsCard.map((el, index) => (
        <div
          className={clsx(
            styles.imageContainer,
            index === hoveredAddIndex && styles.hoveredImage
          )}
        >
          <img src={el.ThumbnailURL} alt={el.Name} />
          <Icon
            name="cross"
            className={styles.closeIcon}
            onClick={() => {
              deleteSelectedProduct(el.Id)
            }}
          />
          <Typography className={styles.labelContainer} type="body1">
            {truncateName(el.Name)}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default AdsImages
