import React, { useMemo } from "react"
import clsx from "clsx"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  BarElement,
  ChartData,
  Tooltip,
  ChartOptions,
  Plugin,
} from "chart.js"
import { numberWithCommas } from "@utils/numberUtils"
import Loader from "@components/ui/Loader/Loader"
import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import { LegendList } from "@components/ui/Charts/Legends/LegendList"
import optionsCreator from "./config"
import { lightColors, NULL } from "../utils"

import styles from "./BarChart.module.scss"

ChartJS.register(BarElement, Tooltip)

export interface BarChartProps {
  className?: string
  dataset: DataSetType[]
  labels?: string[]
  title?: string
  subtitle?: string
  colors?: string[]
  isLoading?: boolean
  hideLegends?: boolean
  hideLoader?: boolean
  plugins?: Plugin<"bar">[]
  yAxisRender?: (value: number) => string
  showDatalabels?: boolean
}

export interface DataSetType {
  label: string
  data: number[]
}

const chartDataProvider = (
  dataset: DataSetType[],
  labels: string[] = [""],
  colors: string[]
): ChartData<"bar"> => ({
  labels,
  datasets: dataset.map(({ label, data }, idx) => ({
    label,
    data,
    backgroundColor: colors[idx],
    barPercentage: 1,
    borderAlign: "center",
  })),
})

const BarChart: React.FC<BarChartProps> = ({
  className,
  dataset,
  labels,
  title,
  subtitle,
  colors = lightColors,
  isLoading = false,
  hideLegends = false,
  hideLoader = false,
  plugins,
  showDatalabels = false,
  yAxisRender,
}) => {
  const options: ChartOptions<"bar"> = useMemo(
    () =>
      optionsCreator({
        showLabels: !!labels,
        yAxisCallback: (value) => {
          if (typeof value === "string") return value
          return yAxisRender ? yAxisRender(value) : numberWithCommas(value)
        },
        showDatalabels,
      }),
    [yAxisRender, labels]
  )

  const data = useMemo(
    () => chartDataProvider(dataset, labels, colors),
    [dataset, labels, colors]
  )

  return (
    <Box className={clsx(styles.root, className)} padding="25px 10px">
      {title != null && (
        <Typography type="h2" color="dark" bold>
          {title}
        </Typography>
      )}
      {subtitle != null && (
        <Typography type="subhead1" color="gray" upperCase>
          {subtitle}
        </Typography>
      )}
      {!hideLoader && <Loader isLoading={isLoading} />}
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          <Bar
            data={data}
            options={options}
            height={NULL}
            width={NULL}
            plugins={plugins}
          />
        </div>
      </div>
      {hideLegends ? null : (
        <div className={styles.legendsContainer}>
          <LegendList
            items={dataset.map(({ label }) => ({ label, dashed: false }))}
            colors={colors}
            key="legends"
          />
        </div>
      )}
    </Box>
  )
}

export default BarChart
