import React from "react"
import { useField } from "formik"
import { observer } from "mobx-react-lite"
import ReactInputMask from "react-input-mask"

import TextField, { TextFieldProps } from "@components/ui/TextField/TextField"
import Label from "@components/ui/Label/Label"
import { useFormMetaContext } from "@framework/prototypes/FormMetaContext"

export interface FormMaskFieldProps
  extends Omit<TextFieldProps, "onChange" | "value"> {
  name: string
  label?: React.ReactNode
  mask?: string
  maskChar?: string
  alwaysShowMask?: boolean
  afterBlur?: (
    e: React.FocusEvent<HTMLInputElement>,
    field: ReturnType<typeof useField>
  ) => void
}

const PhoneInputField: React.FC<FormMaskFieldProps> = observer(
  ({
    afterBlur,
    name,
    label,
    className,
    placeholder,
    mask = "+1 (999) 999-9999",
    maskChar = "_",
    alwaysShowMask = true,
    ...rest
  }) => {
    const formMeta = useFormMetaContext()

    const fieldContext = useField(name)
    const [field, meta, helpers] = fieldContext

    const error = meta.touched ? meta.error : undefined

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      field.onBlur(e)
      afterBlur?.(e, fieldContext)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const digitsOnly = e.target.value.replace(/\D/g, "")
      helpers.setValue(digitsOnly)
    }
    const displayValue = field.value || ""

    return (
      <Label label={label} error={error} className={className}>
        <ReactInputMask
          mask={mask}
          maskChar={maskChar}
          alwaysShowMask={alwaysShowMask}
          value={displayValue}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {(inputProps: any) => (
            <TextField
              name={name}
              edited={formMeta?.isEdited(name)}
              placeholder={
                Array.isArray(field.value) ? "<varies>" : placeholder
              }
              valid={
                meta.touched &&
                meta.value !== meta.initialValue &&
                meta.error == null
              }
              error={!!error}
              {...inputProps}
              {...rest}
            />
          )}
        </ReactInputMask>
      </Label>
    )
  }
)

export default PhoneInputField
