import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { MetricsTrendType, PerformanceReportV1 } from "@framework/types/metrics"
import SimpleTextCell from "./TableCell/SimpleTextCell"
import RowGroup from "./RowGroup"
import { ColumnMapper, GetValueCallbackType, RenderCallbackType } from "./types"
import { makeDescriptor } from "./utils"

import styles from "./Row.module.scss"

export const DefaultCellDescriptor = makeDescriptor(SimpleTextCell)

interface RowProps<T extends object = any> {
  staticBefore?: number
  mapper: ColumnMapper<T>[]
  data: PerformanceReportV1
  metricsTrend: MetricsTrendType
  onClick?: () => void
  defaultRenderCallback?: RenderCallbackType<T>
  defaultGetValueCallback?: GetValueCallbackType<T>
}

const Row: React.FC<any> = observer(
  <T extends object = any>({
    metricsTrend,
    mapper,
    data,
    staticBefore = 0,
    onClick,
  }: RowProps<T>) => {
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")

    const staticMapper = mapper.slice(0, staticBefore)
    const mainMapper = mapper.slice(staticBefore, mapper.length)

    const renderDataCell = (item: ColumnMapper<T>) => {
      if (!data) return <Skeleton count={2} />
      return <Cell rowMapper={item} data={data} metricsTrend={metricsTrend} />
    }

    const renderCell = (item: ColumnMapper<T>) => (
      <div className={clsx(styles.cell, styles.bodyCell)}>
        {renderDataCell(item)}
      </div>
    )

    return (
      <div className={styles.row}>
        <RowGroup
          name="static"
          mapper={staticMapper}
          className={clsx(styles.group, styles.staticGroup, {
            [styles.clickable]: onClick,
          })}
          renderCell={renderCell}
          onClick={onClick}
          key="static"
        />

        <RowGroup
          name="main"
          mapper={mainMapper}
          className={styles.group}
          renderCell={renderCell}
          withShadow
          key="main"
        />
      </div>
    )
  }
)

export interface CellProps<T> {
  rowMapper: ColumnMapper<T>
  data: PerformanceReportV1
  metricsTrend?: MetricsTrendType
}

export const Cell = observer(
  <T,>({ rowMapper, data, metricsTrend }: CellProps<T>) => {
    const props = {
      name: rowMapper.name,
      data,
      metricsTrend,
    }
    const { Component, defaultProps } = rowMapper.view ?? DefaultCellDescriptor
    return <Component {...defaultProps} {...props} />
  }
)

export default Row
