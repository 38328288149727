import { useCallback, useEffect, useState, useRef } from "react"

const useInfiniteScroll = (
  callback: () => void,
  threshold = 100,
  delay = 100
) => {
  const [isFetching, setIsFetching] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  const handleScroll = useCallback(() => {
    const { scrollHeight } = document.documentElement
    const { scrollTop } = document.documentElement
    const { clientHeight } = document.documentElement

    if (scrollHeight - scrollTop - clientHeight <= threshold && !isFetching) {
      setIsFetching(true)
    }
  }, [threshold, isFetching])

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    let isSubscribed = true

    if (!isFetching) return

    const fetchMoreData = async () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(async () => {
        try {
          await callback()
          if (isSubscribed) {
            setIsFetching(false)
          }
        } catch (error) {
          if (isSubscribed) {
            setIsFetching(false)
          }
          console.error("Error fetching data:", error)
        }
      }, delay)
    }

    fetchMoreData()
    // eslint-disable-next-line consistent-return
    return () => {
      isSubscribed = false
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [isFetching, callback, delay])

  return { isFetching }
}

export default useInfiniteScroll
