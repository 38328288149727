import Label from "@components/ui/Label/Label"
import Stack from "@components/ui/Stack/Stack"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import React from "react"
import Radio from "@components/ui/CheckBox/Radio/Radio"

interface AccessLevelSelectorProps {
  value: string
  onChange: (e: React.ChangeEvent<any>) => void
}

const AccessLevelSelector: React.FC<AccessLevelSelectorProps> = ({
  value,
  onChange,
}) => (
  <Label label="Access level:">
    <Stack gutter="4">
      <InlineLabel text="Client" color="black60Color">
        <Radio
          id="accessLevelClient"
          name="accessLevel"
          value="Client"
          checked={value === "Client"}
          onChange={onChange}
        />
      </InlineLabel>
      {/* Uncomment when needed
        <InlineLabel text="Agency" color="black60Color">
          <Radio
            id="accessLevelAgency"
            name="accessLevel"
            value="Agency"
            checked={value === "Agency"}
            onChange={onChange}
          />
        </InlineLabel>
        */}
    </Stack>
  </Label>
)

export default AccessLevelSelector
