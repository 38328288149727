import React, { useEffect, useMemo, useRef } from "react"
import { Formik, FormikProps } from "formik"
import { observer } from "mobx-react-lite"

import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import useToggle from "@framework/hooks/useToggle"
import { useStore } from "@store/index"
import { difference } from "@components/utils/object"
import { countChangesCount } from "@store/product-feed/utils"
import accountService from "@services/account.service"
import {
  editableProductFeedMapper,
  productEditColumnsMapper,
} from "../productEditFormMapper"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { CompareTable } from "../CompareTable/CompareTable"

import styles from "./CompareFeedSidebar.module.scss"

interface CompareFeedSidebarProps {
  productId: number
  isOpened: boolean
  onClose: () => void
}

export const CompareFeedSidebar: React.FC<CompareFeedSidebarProps> = observer(
  ({ productId, isOpened, onClose }) => {
    const formikRef = useRef<FormikProps<any>>(null)

    const {
      accountStore: { accountId },
      productFeedStore: { list, changes, reloadData },
    } = useStore()

    const {
      isOpened: isEdit,
      handleToggle: toggleEdit,
      setOpened: setEdit,
    } = useToggle()

    useEffect(() => {
      if (!isOpened) setEdit(false)
    }, [isOpened])

    const data = list.productById(productId)
    const change = changes.changeById(productId)
    const changeData = change?.data

    const initialValues = useMemo(
      () =>
        editableProductFeedMapper.reduce<any>((acc, item) => {
          const { name, edit } = item
          if (edit == null) return acc
          const value =
            changeData?.update?.[name as keyof typeof changeData.update] ??
            data?.[name]
          if (!value) return acc

          if ((value as any)?.values) acc[name] = (value as any)?.control
          else acc[name] = value
          return acc
        }, {}),
      [data, changeData]
    )

    const handleSaveConnectedAds = async (connectedAds: string) => {
      if (!connectedAds) return
      const reqBody = {
        add: [],
        remove: [
          {
            adIds: [...connectedAds],
            productVariantId: Number(productId),
          },
        ],
      }
      if (accountId) {
        await accountService.setMetaAdsList(accountId, reqBody)
        reloadData()
      }
    }

    const handleSubmit = async (form: any) => {
      if (!data || !data.product_id || !accountId) return
      const { product_id: id } = data

      const { connectedAds, ...rest } = form

      handleSaveConnectedAds(connectedAds)
      const newValue = difference(rest, initialValues)

      if (!Object.entries(newValue).length) {
        setEdit(false)
        return
      }
      const error = await changes.addChange(accountId, id, newValue)
      if (!error) setEdit(false)
    }

    const handleClose = changes.editChangeLoading ? undefined : onClose

    useEffect(() => {
      if (formikRef.current && !isEdit)
        formikRef.current.resetForm({ values: initialValues })
    }, [isEdit, initialValues])

    const isUpdating = change.isLoading || list.isLoading

    const changesList = useMemo(
      () => (data && changeData ? countChangesCount(data, changeData) : []),
      [changeData, data]
    )

    const hasChanges = changesList.length > 0

    return (
      <SidebarContainer isOpen={isOpened} onClose={handleClose}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {() => (
            <div className={styles.root}>
              <Header
                status={changeData?.meta.status ?? data?.status ?? "published"}
                changes={changeData?.meta?.changes}
                isEdit={isEdit}
                onToggleEdit={toggleEdit}
                isLoading={isUpdating}
              />
              <div className={styles.body}>
                <CompareTable
                  data={data}
                  changes={changeData}
                  mapper={productEditColumnsMapper}
                  context={{ isEdit }}
                />
              </div>
              {!isEdit && !!productId && (
                <Footer productId={productId} hasChanges={hasChanges} />
              )}
            </div>
          )}
        </Formik>
      </SidebarContainer>
    )
  }
)

export default CompareFeedSidebar
