import { ChartOptions } from "chart.js"

export type CallbackType = (
  tickValue: number | string,
  index: number
) => string | string[] | number | number[] | null | undefined

export interface BarChartOptionsProps {
  showLabels: boolean
  showDatalabels: boolean
  yAxisCallback?: CallbackType
  xAxisCallback?: CallbackType
}

export const barChartOptions = ({
  yAxisCallback,
  xAxisCallback,
  showLabels,
  showDatalabels,
}: BarChartOptionsProps): ChartOptions<"bar"> => ({
  aspectRatio: 1.5,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 15,
    },
  },
  scales: {
    x: {
      display: showLabels,
      ticks: {
        font: {
          family: "'Poppins'",
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      position: "left",
      beginAtZero: true,
      ticks: {
        font: { family: "'Poppins'" },
        padding: 10,
        callback(value, idx) {
          return yAxisCallback?.(value, idx)
        },
      },
      grid: {
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      axis: "y",
      callbacks: {
        label(item) {
          const value = item.raw ? Number.parseFloat(item.raw as string) : 0
          const formatted = yAxisCallback?.(value, item.dataIndex) ?? value
          return `${item.label}
            ${formatted}`
        },
      },
    },
    datalabels: {
      display: showDatalabels,
    },
  },
})

export default barChartOptions
