import React, { useEffect } from "react"
import { useFormikContext } from "formik"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { ConnectedAd } from "@framework/types/account"
import Icon from "@components/ui/Icon/Icon"
import { ViewCellProps } from "../../types"

import styles from "./TableCell.module.scss"

interface ChipsCellProps extends ViewCellProps {
  editable: boolean
}

const ChipsCell: React.FC<ChipsCellProps> = React.memo(
  ({ data, name, editable }) => {
    const formik = useFormikContext<any>()

    const handleDeleteAds = (id: string) => {
      const connectedAds = formik.values.connectedAds
        ? [...formik.values.connectedAds, id]
        : [id]

      formik.setValues({
        ...formik.values,
        connectedAds,
      })
    }

    const value = data[name]

    return (
      <Stack direction="row" gutter="4" className={styles.adsContainer}>
        {value
          ?.filter((el: any) => !formik.values?.connectedAds?.includes(el.id))
          .map((ad: ConnectedAd) => (
            <Stack
              key={ad.id}
              gutter="4"
              direction="row"
              align="center"
              className={styles.chipsContainer}
            >
              <Typography type="body2" color="primary100Color">
                {ad.name}
              </Typography>
              {editable && (
                <Icon
                  name="cross"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    handleDeleteAds(ad.id)
                  }}
                />
              )}
            </Stack>
          ))}
      </Stack>
    )
  }
)

export default ChipsCell
