import { observable, action, toJS } from "mobx"
import { getCurrentPeriodOptions } from "@store/reports/utils"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { FilterType } from "@pages/MetaAdsChannel/components/PerformanceGoalCard/PerformanceGoalCard"
import { CAFilterNames } from "@framework/hooks/useCAFilters"
import { AnalyticsFilter } from "@framework/types/creativeAnalytics"
import { MetricDescription } from "@framework/types/metrics"
import { CAFilters, initialCAFilters } from "./utils"

class CAFiltersStore {
  @observable datePickerOptions = getCurrentPeriodOptions()

  @observable allFilters: Record<CAFilterNames, CAFilters> = {
    General: initialCAFilters,
    Summary: initialCAFilters,
    Winners: initialCAFilters,
  }

  @observable activePeriodicity: PerformanceReportPeriodicity = "daily"

  @observable filtersStatus: "base" | "reportView" | "reportEditing" = "base"

  @observable activeGoal: FilterType = "winners"

  @action setStateInited = (filterName: CAFilterNames) => {
    this.allFilters[filterName].isFirstInit = false
  }

  @action setFilter = (filterName: CAFilterNames, filter: CAFilters) => {
    this.allFilters[filterName] = filter
  }

  @action setMetrics = (
    filterName: CAFilterNames,
    metrics: MetricDescription[]
  ) => {
    this.allFilters[filterName].activeMetrics = metrics
  }

  @action setFilterString = (
    filterName: CAFilterNames,
    filterString: string[]
  ) => {
    this.allFilters[filterName].appliedFiltersStrings = filterString
  }

  @action setAppliedFilters = (
    filterName: CAFilterNames,
    appliedFilters: AnalyticsFilter[][]
  ) => {
    this.allFilters[filterName].appliedFilters = appliedFilters
  }

  @action setActivePeriodicity = (
    activePeriodicity: PerformanceReportPeriodicity
  ) => {
    this.activePeriodicity = activePeriodicity
  }

  @action setFiltersStatus = (
    status: "base" | "reportView" | "reportEditing"
  ) => {
    this.filtersStatus = status
  }

  @action setActiveGoal = (status: FilterType) => {
    this.activeGoal = status
  }
}

export default CAFiltersStore
