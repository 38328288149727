import React from "react"
import emptyState from "@public/images/empty-state.svg"
import Stack from "../Stack/Stack"
import Typography from "../Typography/Typography"

import styles from "./Empty.module.scss"

const EmptyState = () => (
  <Stack direction="column" gutter="24" align="center" className={styles.root}>
    <img src={emptyState} height={198} alt="empty_state" />
    <Stack direction="column" align="center" gutter="8">
      <Typography type="h3" color="primary80Color">
        Nothing to show here
      </Typography>
      <Typography type="h3">
        Select metrics to compare your ads performance
      </Typography>
    </Stack>
  </Stack>
)

export default EmptyState
