import React, { useRef, useEffect } from "react"
import { Bar } from "react-chartjs-2"
import { ChartOptions, Chart } from "chart.js"
import { getMetricDescriptor } from "@framework/constants/metrics"

import styles from "./AdCardsChart.module.scss"

const HOVER_RESET_DELAY = 50

interface ChartItemProps {
  chartData: {
    labels: string[]
    datasets: {
      label: string
      data: number[]
      backgroundColor: string
      yAxisID: string
    }[]
  }
  onChartHover: (index: number | null) => void
}

const AdCardsChart: React.FC<ChartItemProps> = ({
  chartData,
  onChartHover,
}) => {
  const chartRef = useRef<Chart<"bar"> | null>(null)
  const activeElementRef = useRef<number | null>(null)
  const hoverTimeoutRef = useRef<number | null>(null)

  const options: ChartOptions<any> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#E3E3E4",
        titleColor: "#212121",
        bodyColor: "#212121",
        titleFont: {
          weight: "normal",
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        callbacks: {
          title: (context: any) => {
            if (context && context.length > 0) {
              return context[0].dataset.label
            }
            return null
          },
          label: (context: any) => {
            const { label } = context.dataset
            const metric = getMetricDescriptor(label)
            if (label === "ConversionRate") {
              return metric.formatter(context.parsed.c)
            }

            return metric.formatter(context.parsed.y)
          },
          afterLabel: () => "",
        },
        displayColors: false,
      },
      datalabels: {
        display: "auto",
        color: "#9C9EA2",
        anchor: "end",
        borderRadius: 4,
        align: "top",
        font: {
          family: "Poppins",
          size: 10,
          weight: 700,
        },
        backgroundColor: "#F8F8F8",
        formatter: (value: number, context: any) => {
          const { label } = context.dataset
          const metric = getMetricDescriptor(label)
          return value ? metric.formatter(value) : null
        },
      },
    },
    scales: {
      x: {
        display: false,
      },
      cost: {
        display: false,
        position: "left",
      },
      impressions: {
        display: false,
        position: "left",
      },
      thumbstop: {
        display: false,
        position: "left",
      },
      conversionrate: {
        display: false,
        min: 0,
        max: 1,
      },
    },
    barPercentage: 0.75,
    categoryPercentage: 0.35,
    layout: {
      padding: {
        left: 0,
        right: -20,
        top: 20,
      },
    },
    onHover: (event: any, elements: any[], chart: any) => {
      if (hoverTimeoutRef.current !== null) {
        window.clearTimeout(hoverTimeoutRef.current)
        hoverTimeoutRef.current = null
      }

      if (elements.length > 0) {
        const { index } = elements[0]
        if (activeElementRef.current !== index) {
          activeElementRef.current = index
          onChartHover(index)
        }
      } else {
        hoverTimeoutRef.current = window.setTimeout(() => {
          if (activeElementRef.current !== null) {
            activeElementRef.current = null
            onChartHover(null)
          }
          hoverTimeoutRef.current = null
        }, HOVER_RESET_DELAY)
      }
    },
    borderRadius: 100,
    borderSkipped: false,
  }

  useEffect(() => {
    const chartContainer = document.querySelector(`.${styles.chartContainer}`)

    const handleMouseMove = (e: MouseEvent) => {
      const chart = chartRef.current
      if (!chart) return

      // Fix: Access chart directly instead of using chartInstance
      const { chartArea } = chart

      const rect = chart.canvas.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      const isOutsideChartArea =
        x < chartArea.left ||
        x > chartArea.right ||
        y < chartArea.top ||
        y > chartArea.bottom

      if (isOutsideChartArea && activeElementRef.current !== null) {
        if (hoverTimeoutRef.current !== null) {
          window.clearTimeout(hoverTimeoutRef.current)
        }

        hoverTimeoutRef.current = window.setTimeout(() => {
          activeElementRef.current = null
          onChartHover(null)
          hoverTimeoutRef.current = null
        }, HOVER_RESET_DELAY)
      }
    }

    if (chartContainer) {
      chartContainer.addEventListener(
        "mousemove",
        handleMouseMove as EventListener
      )
    }

    return () => {
      if (chartContainer) {
        chartContainer.removeEventListener(
          "mousemove",
          handleMouseMove as EventListener
        )
      }
      if (hoverTimeoutRef.current !== null) {
        window.clearTimeout(hoverTimeoutRef.current)
      }
    }
  }, [onChartHover])

  return (
    <div className={styles.chartItem}>
      <div
        className={styles.chartContainer}
        style={{
          minWidth: `${chartData.datasets[0].data.length * 150}px`,
        }}
        onMouseLeave={() => {
          if (hoverTimeoutRef.current !== null) {
            window.clearTimeout(hoverTimeoutRef.current)
          }
          hoverTimeoutRef.current = window.setTimeout(() => {
            activeElementRef.current = null
            onChartHover(null)
            hoverTimeoutRef.current = null
          }, HOVER_RESET_DELAY)
        }}
      >
        <Bar
          ref={chartRef}
          data={chartData}
          options={options}
          height={285}
          style={{
            width: `${chartData.datasets[0].data.length * 150}px`,
            height: "285px",
          }}
          className={styles.bars}
        />
      </div>
    </div>
  )
}

export default AdCardsChart
