import { ViewType } from "@pages/MetaAdsChannel/types"
import {
  AnalyticsFilter,
  defaultMetrics,
} from "@framework/types/creativeAnalytics"
import {
  ADSAnalyticsGroupType,
  ADSAnalyticsSummaryGroupType,
} from "@services/creative-analytics"
import { getCurrentPeriodOptions } from "@store/reports/utils"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { MetricDescription } from "@framework/types/metrics"
import { metricsDescriptors } from "@framework/constants/metrics"

export interface CAFilters {
  isFirstInit: boolean
  view: ViewType
  filters: AnalyticsFilter[][]
  groupingType: ADSAnalyticsGroupType | "None"
  summurygroupingType?: ADSAnalyticsSummaryGroupType
  sortByMetric: {
    value: string
    order: "desc" | "asc"
  }
  period: DateRangeOption
  sortColorMetrics: string[]
  appliedFilters: AnalyticsFilter[][]
  appliedFiltersStrings: string[]
  activeMetrics: MetricDescription[]
}

export const initialCAFilters: CAFilters = {
  isFirstInit: true,
  view: "card",
  filters: [],
  groupingType: "None",
  summurygroupingType: "AllAds",
  sortByMetric: { order: "desc", value: "Cost" },
  period: getCurrentPeriodOptions()[0],
  sortColorMetrics: [],
  appliedFilters: [],
  appliedFiltersStrings: [],
  activeMetrics: metricsDescriptors.filter((md) =>
    defaultMetrics.includes(md.name)
  ),
}

export default {}
