import React from "react"
import FormTextField from "@framework/prototypes/FormTextField"

interface YearlyBudgetFieldProps {
  label: string
  name: string
}

const YearlyBudgetField: React.FC<YearlyBudgetFieldProps> = ({
  name,
  label,
}) => <FormTextField before={<span>$</span>} name={name} label={label} />

export default YearlyBudgetField
