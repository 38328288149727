import { observable, action } from "mobx"
import { ViewType } from "@pages/MetaAdsChannel/types"
import { AnalyticsFilter } from "@framework/types/creativeAnalytics"
import {
  ADSAnalyticsGroupType,
  ADSAnalyticsSummaryGroupType,
} from "@services/creative-analytics"
import { getCurrentPeriodOptions } from "@store/reports/utils"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { FilterType } from "@pages/MetaAdsChannel/components/PerformanceGoalCard/PerformanceGoalCard"

interface AnalyticsFilters {
  view: ViewType
  filters: AnalyticsFilter[][]
  groupingType: ADSAnalyticsGroupType | "None"
  summurygroupingType?: ADSAnalyticsSummaryGroupType
  sortByMetric: {
    value: string
    order: "desc" | "asc"
  } | null
  period: DateRangeOption
  sortColorMetrics: string[]
}
class AnalyticsFiltersStore {
  @observable datePickerOptions = getCurrentPeriodOptions()

  @observable allFilters: AnalyticsFilters = {
    view: "card",
    filters: [],
    groupingType: "None",
    summurygroupingType: "AllAds",
    sortByMetric: null,
    period: getCurrentPeriodOptions()[0],
    sortColorMetrics: [],
  }

  @observable activePeriodicity: PerformanceReportPeriodicity = "daily"

  @observable filtersStatus: "base" | "reportView" | "reportEditing" = "base"

  @observable activeGoal: FilterType = "winners"

  @action setAllFilters = (filters: AnalyticsFilters) => {
    this.allFilters = filters
  }

  @action setActivePeriodicity = (
    activePeriodicity: PerformanceReportPeriodicity
  ) => {
    this.activePeriodicity = activePeriodicity
  }

  @action setFiltersStatus = (
    status: "base" | "reportView" | "reportEditing"
  ) => {
    this.filtersStatus = status
  }

  @action setActiveGoal = (status: FilterType) => {
    this.activeGoal = status
  }
}

export default AnalyticsFiltersStore
