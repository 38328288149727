import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Box from "@components/ui/Box/Box"
import SimpleMultiselect from "@components/ui/DropDown/SimpleMultiselect"
import { mbNoData } from "@services/utils"
import { printDateRange } from "@utils/date"
import {
  getCampaignGroupOption,
  getPlatformOption,
} from "@framework/constants/report"
import TimePeriodPicker from "@components/ui/DatePicker/TimePeriodPicker"

import styles from "./index.module.sass"

type ReportsControlHeaderProps = {
  className?: string
}

const ReportsControlHeader: React.FC<ReportsControlHeaderProps> = observer(
  ({ className }) => {
    const {
      accountStore: { getAccountById, loadAccountList },
      reports: { mainFilter },
    } = useStore()

    const currPeriod = mainFilter.currPeriod.selectedOptions[0]
    const prevPeriod = mainFilter.prevPeriod.selectedOptions[0]

    useEffect(() => {
      loadAccountList()
    }, [])

    return (
      <Box className={clsx(styles.root, className)}>
        <span>
          <div className={styles.input}>
            <Typography
              className={styles.periodLabel}
              type="subhead1"
              color="gray"
            >
              {mbNoData(currPeriod?.label)} fjdhvbfdjh
            </Typography>
            <TimePeriodPicker
              maxDate={mainFilter.maxDate}
              range={currPeriod}
              prev={prevPeriod}
              rangeOptions={mainFilter.currPeriod.options}
              prevOptions={mainFilter.prevPeriod.options}
              onRangeChange={(o) => mainFilter.currPeriod.updateOption(o, true)}
              onPrevChange={(v) => mainFilter.prevPeriod.select(v.value, true)}
            />
          </div>
          <Typography
            className={styles.helperText}
            type="caption2"
            color="gray"
          >
            {mbNoData(prevPeriod?.label)}
            {": "}
            {mbNoData(prevPeriod?.range, printDateRange)}
          </Typography>
        </span>

        <div className={styles.input}>
          <Typography type="subhead1" color="gray">
            Account
          </Typography>
          <SimpleMultiselect
            renderValue={(it) => getAccountById(it)?.name ?? it}
            options={mainFilter.accounts.options}
            value={Array.from(mainFilter.accounts.selected)}
            allDisabled={mainFilter.disabledAccounts}
            onSelect={(o) => mainFilter.accounts.select(o)}
            isAllSelected={mainFilter.accounts.isAllSelected}
            onAllSelect={() => mainFilter.accounts.selectAll()}
            unit="account"
          />
        </div>

        <div className={styles.input}>
          <Typography type="subhead1" color="gray">
            Platform
          </Typography>
          <SimpleMultiselect
            renderValue={(it) => getPlatformOption(it).label}
            options={mainFilter.platforms.options}
            value={Array.from(mainFilter.platforms.selected)}
            onSelect={(o) => mainFilter.platforms.select(o)}
            isAllSelected={mainFilter.platforms.isAllSelected}
            onAllSelect={() => mainFilter.platforms.selectAll()}
            unit="platform"
          />
        </div>

        <div className={clsx(styles.input, styles.longFiled)}>
          <Typography type="subhead1" color="gray">
            Campaign Type
          </Typography>
          <SimpleMultiselect
            renderValue={(it) => getCampaignGroupOption(it).label}
            options={mainFilter.campaignTypes.options}
            value={Array.from(mainFilter.campaignTypes.selected)}
            onSelect={(o) => mainFilter.campaignTypes.select(o)}
            isAllSelected={mainFilter.campaignTypes.isAllSelected}
            onAllSelect={() => mainFilter.campaignTypes.selectAll()}
            unit="campaign"
          />
        </div>

        <Button
          color="secondary"
          onClick={mainFilter.cancelFilter}
          disabled={mainFilter.isSync}
          size="big"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={mainFilter.applyFilters}
          disabled={mainFilter.isSync}
          size="big"
        >
          Apply
        </Button>
      </Box>
    )
  }
)

export default ReportsControlHeader
