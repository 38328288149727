import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { MetaCampaignStatus } from "@pages/MetaAdsChannel/types"

export const StatusBadge: React.FC<{
  status?: MetaCampaignStatus
  short?: boolean
  className?: string
}> = ({ status = "INACTIVE", short = false, className }) => (
  <Stack direction="row" align="center" gutter="8" className={className}>
    {getIcon(status)}

    {!short && (
      <Typography type="caption2" color="black100Color">
        {statusMap[status] ?? "Unknown"}
      </Typography>
    )}
  </Stack>
)

export default StatusBadge

const statusMap: Record<MetaCampaignStatus, string> = {
  INACTIVE: "Inactive",
  IN_REVIEW: "In Review",
  ACTIVE: "Active",
  LOW_PERFORMANCE: "Warning - low performance",
  FAILED: "Error occurred",
  DELETED: "Deleted",
  PAUSED: "Paused",
}

const getIcon = (status: MetaCampaignStatus) => {
  switch (status) {
    case "ACTIVE":
      return (
        <Icon
          name="checkbox-blank-circle-fill"
          style={{ color: "var(--success-100-color)" }}
        />
      )

    case "IN_REVIEW":
      return (
        <Icon
          name="checkbox-blank-circle"
          style={{ color: "var(--success-100-color)" }}
        />
      )

    case "LOW_PERFORMANCE":
      return (
        <Icon name="alert-fill" style={{ color: "var(--info-100-color)" }} />
      )

    case "DELETED":
      return (
        <Icon name="alert-fill" style={{ color: "var(--error-100-color)" }} />
      )

    case "INACTIVE":
    case "PAUSED":
    default:
      return (
        <Icon
          name="checkbox-blank-circle-fill"
          style={{ color: "var(--black-60-color)" }}
        />
      )
  }
}
