import React, { FC, useState } from "react"
import EmptyState from "@components/ui/EmptyState/EmptyState"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import { MetricDescription, MetricsTrendType } from "@framework/types/metrics"
import mapper from "@pages/MetaAdsChannel/Summary/mapper"
import { ADSAnalyticsResponse } from "@services/creative-analytics"
import CompareTable from "./components/Table/CompareTable"

import styles from "./SummaryChartWithTable.module.scss"
import Charts from "./components/Charts/Charts"

interface SummaryChartWithTableProps {
  activeMetrics: MetricDescription[]
  adsCards: AnalyticsCard[]
  summary: ADSAnalyticsResponse["data"]["Summary"]
  metricsTrend: MetricsTrendType
}

const SummaryChartWithTable: FC<SummaryChartWithTableProps> = ({
  activeMetrics,
  adsCards,
  summary,
  metricsTrend,
}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([])
  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    // TO Do
  }

  return (
    <div className={styles.root}>
      {selectedCheckboxes.length === 0 ? (
        <EmptyState />
      ) : (
        <Charts
          setSelectedAds={setSelectedCheckboxes}
          selectedAds={selectedCheckboxes}
        />
      )}
      <CompareTable
        className={styles.table}
        mapper={Object.values(mapper(activeMetrics, metricsTrend))}
        data={adsCards}
        summary={summary}
        metricsTrend={metricsTrend}
        onRowClick={handleOpenReportDetailsModal}
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
      />
    </div>
  )
}

export default SummaryChartWithTable
