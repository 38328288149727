import { MetaAdsItem } from "@framework/types/account"
import { ID } from "@framework/types/types"
import accountService from "@services/account.service"
import RootStore from "@store/RootStore"
import { action, computed, observable } from "mobx"

class ProductMetaAdsStore {
  @observable rootStore: RootStore

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @observable adsList: MetaAdsItem[] = []

  @observable isLoadinng: boolean = false

  // computed
  @computed get getAdsList(): MetaAdsItem[] {
    return this.adsList
  }

  // actions
  @action loadAdsLisst = async (accountId: ID) => {
    const response = await accountService.getMetaAdsList(accountId)
    if (response.data.data) {
      this.adsList = response.data.data
    }
  }
}

export default ProductMetaAdsStore
