import React, { useEffect, useState } from "react"
import { useStore } from "@store/index"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import clsx from "clsx"
import Stack from "@components/ui/Stack/Stack"
import {
  AnalyticsCard,
  filterEqualityMapper,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import Loader from "@components/ui/Loader/Loader"
import {
  MetricDescription,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import { metricsDescriptors } from "@framework/constants/metrics"
import performanceGoalOptions from "@pages/MetaAdsChannel/Winners/constants"
import PerformanceGoalCard from "@pages/MetaAdsChannel/components/PerformanceGoalCard/PerformanceGoalCard"
import { useAlert } from "react-alert"
import moment from "moment/moment"
import { WinnersLosersSettings } from "@pages/MetaAdsChannel/Winners/Winners"
import { calculateMetricsTrend } from "@pages/MetaAdsChannel/components/utils"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import Typography from "@components/ui/Typography/Typography"
import { useHistory } from "react-router-dom"
import { Button } from "@components/ui/Button"
import SimpleFilterSelect from "@components/ui/FiltersDropDown/SimpleFilterSelect"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import ReportCard from "@pages/MetaAdsChannel/components/ReportCard/ReportCard"
import useToggle from "@framework/hooks/useToggle"
import ReportDetailsModal from "@pages/MetaAdsChannel/components/ReportDetails/ReportDetails"
import KPITrendModal from "@pages/MetaAdsChannel/components/KPITrendChart/KPITrendModal"
import VideoPreviewModal from "@pages/MetaAdsChannel/components/Modals/VIdeoPreviewModal"
import { MetaCampaignStatus } from "@pages/MetaAdsChannel/types"
import { apiDateFormatter } from "@services/utils"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import useCAFilters from "@framework/hooks/useCAFilters"
import Icon from "@components/ui/Icon/Icon"
import SummaryControlPanel from "./SummaryControlPanel"
import KPIOverview from "../components/KPIOverview"
import SummaryChartWithTable from "../components/SummaryChartWithTable/SummaryChartWithTable"
import SortingByColor from "../components/ColorSorting/ColorSorting"

import styles from "./index.module.scss"

type SummaryPageProps = {
  className?: string
}

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)
type MetricOptionValues = (typeof metricOptions)[keyof typeof metricOptions]

const SummaryPage: React.FC<SummaryPageProps> = observer(({ className }) => {
  const {
    accountStore: { accountId },
    creativeAnalyticsStore: {
      adsCardsData,
      isADSCardsLoading,
      isKPILoading,
      KPITrendsData,
      getKPITrends,
      winnersLosersData,
      getWinnersLosersSettings,
      cleanWinnersLosersData,
    },
    analyticsFiltersStore: {
      activePeriodicity,
      setActivePeriodicity,
      activeGoal,
      setActiveGoal,
    },
  } = useStore()
  // eslint-disable-next-line no-empty-pattern
  const [allFilters, setAllFilters, {}, setActiveMetrics] =
    useCAFilters("Summary")
  const { period, sortColorMetrics } = allFilters

  const alert = useAlert()
  const history = useHistory()

  const adDetailsModal = useToggle()
  const KPImodal = useToggle()
  const videoPreviewModal = useToggle()

  const [filteredCards, setFilteredCards] = React.useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )

  const [topAds, setTopAds] = React.useState<AnalyticsCard[]>([])

  const [showMoreTopAds, setShowMoreTopAds] = useState(false)

  const [winnersLosers, setWinnersLosers] = useState<
    Partial<WinnersLosersSettings>
  >({
    scaling_threshold: 0,
    new_period: 0,
    new_period_unit: "days",
    winnersAmount: 0,
    losersAmount: 0,
    scalingAmount: 0,
    newAmount: 0,
  })

  const [scalingFilter, setScalingFilter] =
    useState<WinnersLosersSettings["scaling_threshold"]>(0)

  const [metricsTrend, setMetricsTrend] = useState<MetricsTrendType>({})

  const [showSortByMetric, setShowSortByMetric] = useState(false)

  const [currentSortingMetric, setCurrentSortingMetric] =
    useState<MetricOptionValues>("Cost")

  const [reportDetails, setReportDetails] = useState<AnalyticsCard>()

  const [report, setReport] = useState<{
    id: string
    name: string
    status: MetaCampaignStatus
  } | null>(null)

  const metricsOptions = useOptionSearchList({
    list: metricsOptionsArray ?? [],
  })

  const onViewMoreClick = () => {
    if (accountId) {
      history.replace(`/meta-ads/${accountId}/winners`)
      window.scrollTo(0, 0)
    }
  }

  const handleOpenVideoPreview = (card: AnalyticsCard) => {
    setReportDetails(card)
    videoPreviewModal.setOpened(true)
  }

  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    setReportDetails(card)
    adDetailsModal.handleToggle()
  }

  const handleOpenKPItrendModal = (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => {
    setReport({ id, name, status: status || "INACTIVE" })
    KPImodal.setOpened(true)
  }

  const handleActivePeriodicity = (
    periodicity: PerformanceReportPeriodicity
  ) => {
    setActivePeriodicity(periodicity)
  }

  const handlePreviousDetailsCard = (cardId: string) => {
    const currentIndex = topAds.findIndex(
      (it: AnalyticsCard) => it.Id === cardId
    )
    let prevoriusCard: AnalyticsCard = topAds[currentIndex - 1]
    if (currentIndex === 0) {
      prevoriusCard = topAds[topAds.length - 1]
    }
    setReportDetails(prevoriusCard)
  }

  const handleNextDetailsCard = (cardId: string) => {
    const currentIndex = topAds.findIndex(
      (it: AnalyticsCard) => it.Id === cardId
    )
    let nextCard: AnalyticsCard = topAds[currentIndex + 1]
    if (currentIndex === topAds.length - 1) {
      // eslint-disable-next-line prefer-destructuring
      nextCard = topAds[0]
    }
    setReportDetails(nextCard)
  }

  useEffect(() => {
    if (!accountId || !report) return
    getKPITrends(accountId, report.id, {
      from: apiDateFormatter(period.range[0]),
      to: apiDateFormatter(period.range[1]),
      periodicity: activePeriodicity,
    }).then((res) => {
      if (res) alert.error(res)
    })
  }, [report, activePeriodicity, allFilters])

  useEffect(() => {
    if (adsCardsData.AdCreatives.length) {
      let sortedAds: AnalyticsCard[] = []
      if (!showMoreTopAds) {
        sortedAds = adsCardsData.AdCreatives.sort((a, b) => {
          const aMetric = a[currentSortingMetric] as MetricType
          const bMetric = b[currentSortingMetric] as MetricType
          if (typeof aMetric === "object" && typeof bMetric === "object") {
            return bMetric.Value - aMetric.Value
          }
          return 0
        }).slice(0, 4)
      } else {
        sortedAds = adsCardsData.AdCreatives.sort((a, b) => {
          const aMetric = a[currentSortingMetric] as MetricType
          const bMetric = b[currentSortingMetric] as MetricType
          if (typeof aMetric === "object" && typeof bMetric === "object") {
            return bMetric.Value - aMetric.Value
          }
          return 0
        }).slice(0, 8)
      }
      setTopAds(sortedAds)
    }
  }, [currentSortingMetric, adsCardsData, showMoreTopAds])

  useEffect(() => {
    setScalingFilter(winnersLosers.scaling_threshold || 0)
    const winLosColorMetrics = new Set<string>()
    winnersLosersData?.settings?.metric_criteria.forEach((mc) =>
      winLosColorMetrics.add(mc.metric_name)
    )
    setAllFilters({ ...allFilters })
  }, [winnersLosersData, winnersLosers, adsCardsData])

  useEffect(() => {
    const res = calculateMetricsTrend(
      adsCardsData.AdCreatives,
      sortColorMetrics
    )
    setMetricsTrend(res)
  }, [adsCardsData, sortColorMetrics])

  useEffect(() => {
    setFilteredCards(adsCardsData.AdCreatives)
  }, [adsCardsData])

  useEffect(() => {
    if (!accountId) return
    getWinnersLosersSettings(accountId).then((res) => {
      if (res) alert.error(res)
    })
  }, [accountId])

  useEffect(() => {
    const winners =
      winnersLosersData?.settings?.metric_criteria?.filter(
        (it) => it.category === "Winners"
      ) || []
    const losers =
      winnersLosersData?.settings?.metric_criteria?.filter(
        (it) => it.category === "Losers"
      ) || []

    let winnersAds: AnalyticsCard[] = []
    if (winners.length) {
      winnersAds = adsCardsData.AdCreatives
      winners.forEach((f) => {
        const filterEqualityMapperType = filterEqualityMapper(f.operator)
        winnersAds = winnersAds.filter((it) => {
          const metric = it[f.metric_name] as MetricType
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, f.value1)
          }
          return false
        })
      })
    }

    let losersAds: AnalyticsCard[] = []
    if (losers.length) {
      losersAds = adsCardsData.AdCreatives
      losers.forEach((f) => {
        const filterEqualityMapperType = filterEqualityMapper(f.operator)
        losersAds = losersAds.filter((it) => {
          const metric = it[f.metric_name] as MetricType
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, f.value1)
          }
          return false
        })
      })
    }

    let scalingAds: AnalyticsCard[] = []
    scalingAds = adsCardsData.AdCreatives.filter((it) => {
      const metric = it.Cost as MetricType
      if (typeof metric === "object") {
        return metric.Value >= scalingFilter
      }
      return false
    })

    let newAds: AnalyticsCard[] = []
    const now = moment()
    newAds = adsCardsData.AdCreatives.filter((ad) => {
      if (!ad.CreatedAt) return false

      const adDate = moment(ad.CreatedAt)
      return adDate.isAfter(
        now
          .clone()
          .subtract(
            winnersLosersData?.settings?.new_period || 0,
            winnersLosersData?.settings?.new_period_unit || "days"
          )
      )
    })

    if (adsCardsData && winnersLosersData) {
      setWinnersLosers({
        scaling_threshold: winnersLosersData?.settings?.scaling_threshold || 0,
        new_period: winnersLosersData?.settings?.new_period || 0,
        new_period_unit: winnersLosersData?.settings?.new_period_unit || "days",
        winnersAmount: winnersAds.length,
        losersAmount: losersAds.length,
        scalingAmount: scalingAds.length,
        newAmount: newAds.length,
      })
    }
  }, [winnersLosersData, adsCardsData, scalingFilter])

  const onMetricsFilterChange = (metrics: string[]) => {
    const filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      metrics.includes(md.name)
    )
    setActiveMetrics(filteredMetricsDescriptors)
  }

  useEffect(
    () => () => {
      setWinnersLosers({
        scaling_threshold: 0,
        new_period: 0,
        new_period_unit: "days",
        winnersAmount: 0,
        losersAmount: 0,
        scalingAmount: 0,
        newAmount: 0,
      })
      cleanWinnersLosersData()
    },
    []
  )

  useEffect(() => {
    console.log(toJS(allFilters.activeMetrics))
  }, [allFilters.activeMetrics])

  return (
    <article className={clsx(styles.root, className)}>
      <Stack direction="column" align="stretch">
        <SummaryControlPanel
          activeMetrics={allFilters.activeMetrics.map((metric) => metric.name)}
          onMetricsFilter={onMetricsFilterChange}
        />
        {isADSCardsLoading ? (
          <Loader />
        ) : (
          <>
            <SortingByColor
              activeMetrics={allFilters.activeMetrics}
              setActiveMetrics={setActiveMetrics}
            />

            <section>
              <KPIOverview summaryReports={adsCardsData.Summary} />
              <SummaryChartWithTable
                activeMetrics={allFilters.activeMetrics}
                summary={adsCardsData.Summary}
                adsCards={filteredCards}
                metricsTrend={metricsTrend}
              />

              <section className={styles.topAds}>
                <Stack direction="row" justify="space-between" align="center">
                  <Typography weight="bold" type="h2">
                    Top ads
                  </Typography>
                  <div className={styles.topAdsControlPanel}>
                    <div className={styles.filterButton}>
                      <Button
                        before={<Icon name="eye" />}
                        color="secondary"
                        onClick={() => setShowSortByMetric((prev) => !prev)}
                      >
                        {currentSortingMetric}
                      </Button>
                      {showSortByMetric && (
                        <div className={styles.metricsSelect}>
                          <SimpleFilterSelect
                            className={styles.select}
                            onSelect={(value) => setCurrentSortingMetric(value)}
                            onClickOutside={() => setShowSortByMetric(false)}
                            value={currentSortingMetric}
                            {...metricsOptions}
                          />
                        </div>
                      )}
                    </div>
                    <Button variant="ghost">
                      <Typography
                        weight="bold"
                        type="h5"
                        color="primary"
                        onClick={() => setShowMoreTopAds((prev) => !prev)}
                      >
                        {showMoreTopAds ? "View less" : "View more"}
                      </Typography>
                    </Button>
                  </div>
                </Stack>
                <div className={styles.topAdsList}>
                  {topAds.map((ad) => (
                    <ReportCard
                      onOpenVideoPreview={handleOpenVideoPreview}
                      data={ad}
                      metricsTrend={metricsTrend}
                      metrics={allFilters.activeMetrics}
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        handleOpenReportDetailsModal(ad)
                      }}
                      onOpenKPIChart={handleOpenKPItrendModal}
                      key={ad.Id}
                    />
                  ))}
                </div>
              </section>

              <Stack
                direction="row"
                justify="space-between"
                align="center"
                className={styles.winnersTitle}
              >
                <Typography weight="bold" type="h2">
                  Latest winners selection
                </Typography>
                <Button variant="ghost" onClick={onViewMoreClick}>
                  <Typography weight="bold" type="h5" color="primary">
                    View more
                  </Typography>
                </Button>
              </Stack>
              <div className={styles.goalsGrid}>
                {performanceGoalOptions.map((item, idx) => {
                  let total = 0
                  switch (item.filterType) {
                    case "winners":
                      total = winnersLosers.winnersAmount || 0
                      break
                    case "losers":
                      total = winnersLosers.losersAmount || 0
                      break
                    case "scaling":
                      total = winnersLosers.scalingAmount || 0
                      break
                    case "new":
                      total = winnersLosers.newAmount || 0
                      break
                    default:
                  }
                  return (
                    <PerformanceGoalCard
                      {...item}
                      total={total}
                      key={item.title}
                      active={activeGoal === item.filterType}
                      onClick={() => setActiveGoal(item.filterType)}
                    />
                  )
                })}
              </div>
            </section>
          </>
        )}
      </Stack>

      <ReportDetailsModal
        open={adDetailsModal.isOpened}
        onClose={() => adDetailsModal.setOpened(false)}
        reportDetails={reportDetails as AnalyticsCard}
        metricsTrend={metricsTrend}
        onPreviousClick={handlePreviousDetailsCard}
        onNextClick={handleNextDetailsCard}
      />

      <KPITrendModal
        activePeriodicity={activePeriodicity}
        onActivePeriodicity={handleActivePeriodicity}
        report={report}
        data={KPITrendsData}
        open={KPImodal.isOpened}
        isKPILoading={isKPILoading}
        onClose={() => KPImodal.setOpened(false)}
      />

      {videoPreviewModal.isOpened && reportDetails ? (
        <VideoPreviewModal
          data={reportDetails}
          onDetailsOpen={() => {
            adDetailsModal.setOpened(true)
            videoPreviewModal.setOpened(false)
          }}
          onClose={() => videoPreviewModal.setOpened(false)}
        />
      ) : null}
    </article>
  )
})

export default SummaryPage
