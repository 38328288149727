import clsx from "clsx"
import React from "react"

import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import Typography from "@components/ui/Typography/Typography"
import { Option } from "@framework/types/types"
import { mbNoData } from "@services/utils"
import SimpleTextCell from "../CompareFeedSidebar/TableCell/SimpleTextCell"
import {
  GetValueCallbackType,
  ColumnMapper,
  RenderCallbackType,
  makeDescriptor,
} from "../types"

import styles from "./CompareTable.module.scss"

export const defaultGetValue: GetValueCallbackType<any> = (data, mapper) =>
  data[mapper.name]

export const defaultCellRender: RenderCallbackType<any> = (value) => (
  <Typography className={styles.defaultText}>{mbNoData(value)}</Typography>
)

export const DefaultCellDescriptor = makeDescriptor(SimpleTextCell)

const columnList: Option[] = [
  { value: "row", label: "Row" },
  { value: "transformed", label: "Transformed" },
  { value: "raw", label: "Raw" },
]

interface CompareTableProps {
  data?: any
  changes?: any
  context?: any
  mapper: ColumnMapper<any>[]
}

export const CompareTable: React.FC<CompareTableProps> = ({
  data = {},
  changes = {},
  context = null,
  mapper,
}) => {
  const renderHeaderCol = ({ label, value }: Option) => (
    <div className={styles.col} key={value}>
      <Typography type="h5" color="gray" upperCase>
        {label}
      </Typography>
    </div>
  )

  return (
    <div className={styles.root}>
      <div className={clsx(styles.header, styles.row)}>
        {columnList.map(renderHeaderCol)}
      </div>
      <ScrollContainer type="secondary" className={styles.body}>
        {mapper.map((rowMapper) => (
          <div className={styles.row} key={rowMapper.name.toString()}>
            <div className={styles.col} key={columnList[0].value}>
              <Cell
                rowMapper={rowMapper}
                columnItem={columnList[0]}
                isEdit={context.isEdit}
                data={data}
                changes={changes}
              />
            </div>
            <div className={styles.col} key={columnList[1].value}>
              <Cell
                rowMapper={rowMapper}
                columnItem={columnList[1]}
                isEdit={context.isEdit}
                data={data}
                changes={changes}
              />
            </div>
          </div>
        ))}
      </ScrollContainer>
    </div>
  )
}

interface CellProps {
  rowMapper: ColumnMapper
  columnItem: Option
  isEdit: boolean
  data: any
  changes: any
}

const Cell: React.FC<CellProps> = React.memo(
  ({ rowMapper, columnItem, isEdit, data, changes }) => {
    const props = {
      name: rowMapper.name,
      data,
    }

    if (columnItem.value === "row") {
      return (
        <Typography className={styles.label} type="h5" color="gray" upperCase>
          {rowMapper.label}
        </Typography>
      )
    }

    if (columnItem.value === "transformed") {
      if (isEdit && rowMapper.edit) {
        const { Component, defaultProps } =
          rowMapper.edit ?? DefaultCellDescriptor
        return <Component {...defaultProps} {...props} />
      }
      const { Component, defaultProps } =
        rowMapper.view ?? DefaultCellDescriptor
      return <Component {...defaultProps} {...props} changeData={changes} />
    }

    const { Component, defaultProps } = rowMapper.view ?? DefaultCellDescriptor
    return <Component {...defaultProps} {...props} />
  }
)

export default CompareTable
