import { useCallback } from "react"
import { useStore } from "@store/index"

const useMetaAccountLinkRedirect = () => {
  const {
    accountLinkingStore: { locationLoading, getMetaLink },
  } = useStore()
  const redirect = useCallback(
    async (state: number) => {
      if (locationLoading) return null

      const result = await getMetaLink(
        JSON.stringify(state),
        `${window.location.origin}/account/meta/link/redirect`
      )

      const url = result.data
      if (result.status === "success") window.location.href = url

      return result
    },
    [getMetaLink, locationLoading]
  )

  return { redirect }
}

export default useMetaAccountLinkRedirect
