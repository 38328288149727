import React, { useEffect, useState } from "react"
import clsx from "clsx"
import get from "lodash/get"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import {
  MetricDescription,
  MetricNameTypeV1,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import Image from "@components/ui/Image/Image"
import PlayIcon from "@root/assets/svgIcons/PlayIcon"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import {
  ADSAnalyticsGroupType,
  ADSAnalyticsGroupTypeEntity,
} from "@services/creative-analytics"
import { cutString } from "@utils/stringUtils"
import { calculateTotalMetrics } from "@framework/constants/metrics"
import MetricValueChip from "../MetricValueChip"

import styles from "./ReportGroup.module.sass"

interface GroupCardProps {
  data: ADSAnalyticsGroupTypeEntity
  adsCard: AnalyticsCard
  groupAds: AnalyticsCard[]
  total: number
  groupingType: ADSAnalyticsGroupType | "None"
  metricsTrend: MetricsTrendType
  onOpenVideoPreview: (data: AnalyticsCard) => void
  metrics: MetricDescription[]
  onClick: React.MouseEventHandler
}

export const GroupCard: React.FC<GroupCardProps> = observer(
  ({
    data,
    adsCard,
    groupAds,
    groupingType,
    total,
    metricsTrend,
    onOpenVideoPreview,
    onClick,
    metrics,
  }) => {
    const [groupTotal, setGroupTotal] = useState<
      Record<MetricNameTypeV1, MetricType>
    >({
      AOV: { Value: 0 },
      AverageCpc: { Value: 0 },
      Clicks: { Value: 0 },
      ConversionRate: { Value: 0 },
      Conversions: { Value: 0 },
      Cost: { Value: 0 },
      Ctr: { Value: 0 },
      Impressions: { Value: 0 },
      Revenue: { Value: 0 },
      Roas: { Value: 0 },
      CostConv: { Value: 0 },
      Cpm: { Value: 0 },
      Thumbstop: { Value: 0 },
      Holdplay: { Value: 0 },
    })
    useEffect(() => {
      let res: Record<MetricNameTypeV1, MetricType> = {
        AOV: { Value: 0 },
        AverageCpc: { Value: 0 },
        Clicks: { Value: 0 },
        ConversionRate: { Value: 0 },
        Conversions: { Value: 0 },
        Cost: { Value: 0 },
        Ctr: { Value: 0 },
        Impressions: { Value: 0 },
        Revenue: { Value: 0 },
        Roas: { Value: 0 },
        CostConv: { Value: 0 },
        Cpm: { Value: 0 },
        Thumbstop: { Value: 0 },
        Holdplay: { Value: 0 },
      }
      if (!groupAds) return
      res = calculateTotalMetrics(groupAds)
      setGroupTotal(res)
    }, [groupAds])
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={clsx(styles.root)} onClick={onClick}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={styles.previewContainer}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            if (adsCard?.Type === "IMAGE") return
            onOpenVideoPreview(adsCard)
          }}
        >
          {adsCard?.Type === "IMAGE" &&
          (groupingType === "ByCreative" || groupingType === "ByName") ? (
            <Image
              src={adsCard.ThumbnailURL}
              className={styles.image}
              alt="Preview"
            />
          ) : adsCard?.Type === "VIDEO" &&
            (groupingType === "ByCreative" || groupingType === "ByName") ? (
            <div className={styles.previewVideo}>
              <div className={styles.previewVideoIcon}>
                <PlayIcon />
              </div>
              <Image
                src={adsCard?.ThumbnailURL}
                className={styles.image}
                alt="Preview"
              />
            </div>
          ) : (
            <div className={styles.groupHeader}>
              <Typography
                className={styles.titleOverflow}
                weight="bold"
                type="h5"
              >
                {cutString(data.Name, 250)}
              </Typography>
              <Typography type="caption3" className={styles.titleTooltip}>
                {data.Name}
              </Typography>
            </div>
          )}
        </div>
        {groupingType === "ByCreative" || groupingType === "ByName" ? (
          <div className={styles.groupSubHeader}>
            <Typography
              className={styles.titleOverflow}
              weight="bold"
              type="h5"
            >
              {cutString(data.Name, 50)}
            </Typography>
            <Typography type="caption3" className={styles.titleTooltip}>
              {data.Name}
            </Typography>
          </div>
        ) : null}
        <Stack
          className={styles.metricHeader}
          direction="row"
          justify="flex-start"
          align="center"
        >
          <Typography type="h5" weight="bold">
            {total} {total > 1 || total === 0 ? "ads" : "ad"}
          </Typography>
          <Typography weight="bold" color="primary" type="h5">
            Ad breakdown
          </Typography>
        </Stack>

        <Stack
          className={styles.metricsContainer}
          gutter="4"
          direction="column"
          align="stretch"
        >
          {metrics?.map((metric) => {
            const value = get(groupTotal, metric.name, null)

            let diffFromAverageValue: string = ""
            const isActive = Boolean(metricsTrend && metricsTrend[metric.name])
            if (metricsTrend && metricsTrend[metric.name] && value?.Value) {
              const { min } = metricsTrend[metric.name]
              const { max } = metricsTrend[metric.name]
              const currentValue = value.Value
              const avgPercent =
                (((min + max) / 2 - min) / (max - min)) * 100 || 0
              const currentPercent = ((currentValue - min) / (max - min)) * 100
              const diffFromAvg = currentPercent - avgPercent
              diffFromAverageValue = `Diff from avg: ${
                diffFromAvg > 0
                  ? `+${diffFromAvg.toFixed(1)}`
                  : diffFromAvg.toFixed(1)
              }%`
            }
            return (
              <Stack
                direction="row"
                justify="space-between"
                align="center"
                key={metric.name}
                className={styles.metricWrap}
              >
                <Typography color="primary" weight="bold" type="caption2">
                  {metric.label}
                </Typography>

                <Typography weight="bold" type="caption2">
                  {isActive ? (
                    <Typography
                      color="light"
                      type="caption3"
                      className={styles.metricPopupHelper}
                    >
                      {diffFromAverageValue}
                    </Typography>
                  ) : null}
                  <MetricValueChip
                    className={styles.metricChip}
                    value={value as MetricType}
                    metric={metric}
                    metricsTrend={metricsTrend as MetricsTrendType}
                  />
                </Typography>
              </Stack>
            )
          }) ?? <NoData />}
        </Stack>
      </div>
    )
  }
)

export default GroupCard
