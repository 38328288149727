import React, { Dispatch, FC, useEffect, useMemo, useState } from "react"
import { Legend } from "@components/ui/Charts/Legends/LegendList"
import Stack from "@components/ui/Stack/Stack"
import { useStore } from "@store/index"
import { MetricType } from "@framework/types/metrics"
import { getMetricDescriptor } from "@framework/constants/metrics"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

import BgStripes from "./BgStripes"
import styles from "./Charts.module.scss"
import AdsImages from "./AdsImages/AdsImages"
import AdCardsChart from "./AdCardsChart/AdCardsChart"

const METRICS = {
  COST: "Cost",
  IMPRESSIONS: "Impressions",
  THUMBSTOP: "Thumbstop",
  CONVERSION_RATE: "ConversionRate",
} as const

const CHART_COLORS = {
  [METRICS.COST]: "#2D8DD1",
  [METRICS.IMPRESSIONS]: "#93D692",
  [METRICS.THUMBSTOP]: "#D1E7EC",
  [METRICS.CONVERSION_RATE]: "#EAC974",
} as const

export interface ChartDataItem {
  id: string
  tableInfo: {
    data: number[]
    labels: string[]
  }
  image: string
}

const LEGEND_LIST_ITEMS = Object.entries(METRICS).map(([_, value]) => ({
  label: value,
  color: CHART_COLORS[value],
}))

interface ChartsProps {
  selectedAds: string[]
  setSelectedAds: Dispatch<React.SetStateAction<string[]>>
}

const formattedLegendList = LEGEND_LIST_ITEMS.map((item) => ({
  label: getMetricDescriptor(item.label).shortLabel,
  color: item.color,
}))

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartDataLabels
)

const Charts: FC<ChartsProps> = ({ selectedAds, setSelectedAds }) => {
  const {
    creativeAnalyticsStore: { adsCardsData },
  } = useStore()

  const [currentAd, setCurrentAd] = useState<number | null>(null)

  const getFilteredAdCreatives = () =>
    adsCardsData.AdCreatives.filter((el) => selectedAds.includes(el.Id))

  const createChartDatasets = () =>
    LEGEND_LIST_ITEMS.map((legend) => ({
      label: legend.label,
      data: getFilteredAdCreatives().map((el) => {
        const metric = el[legend.label] as MetricType
        return metric.Value || 0
      }),
      backgroundColor: legend.color,
      yAxisID: legend.label.toLowerCase(),
    }))

  const handleDeleteProduct = (productId: string) => {
    setSelectedAds((prev) => prev.filter((el) => el !== productId))
  }

  const chartData = {
    labels: getFilteredAdCreatives().map((el) => el.Name),
    datasets: createChartDatasets(),
  }

  return (
    <Stack direction="column" gutter="32" className={styles.root}>
      <Stack gutter="12" direction="row">
        {formattedLegendList.map((el) => (
          <Legend label={el.label} color={el.color} />
        ))}
      </Stack>
      <div className={styles.mainInfoContainer}>
        <div className={styles.tableScrollContainer}>
          <div className={styles.tableContainer}>
            {!!selectedAds.length && (
              <AdCardsChart chartData={chartData} onChartHover={setCurrentAd} />
            )}
            <AdsImages
              analyticsCard={getFilteredAdCreatives()}
              deleteSelectedProduct={handleDeleteProduct}
              hoveredAddIndex={currentAd}
            />
          </div>
        </div>
        <BgStripes />
      </div>
    </Stack>
  )
}

export default Charts
