import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"

import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import FormTextField from "@framework/prototypes/FormTextField"

import { CompanyFormValues, initialValues, validationSchema } from "./utils"
import PhoneInputField from "./components/PhoneInputField"
import AccessLevelSelector from "./components/AccessLevelSelector"
import YearlyBudgetField from "./components/YearlyBudgetField"

const formFields = [
  { name: "clientName", label: "Client name", component: FormTextField },
  { name: "clientEmail", label: "Client email", component: FormTextField },
  { name: "contactName", label: "Contact name", component: FormTextField },
  { name: "contactPhone", label: "Contact phone", component: PhoneInputField },
  { name: "website", label: "Website", component: FormTextField },
  { name: "state", label: "State", component: FormTextField },
  { name: "city", label: "City", component: FormTextField },
  { name: "postal", label: "Postal", component: FormTextField },
  { name: "street", label: "Street", component: FormTextField },
  { name: "industry", label: "Industry", component: FormTextField },
  {
    name: "yearlyAdBudget",
    label: "Yearly Ad Budget",
    component: YearlyBudgetField,
  },
  { name: "accountName", label: "Account name", component: FormTextField },
]

export const CompanySetup: React.FC = observer(() => {
  // const store = useStore()

  const formik = useFormik<CompanyFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (data) => {
      console.log(data)
    },
  })

  useEffect(() => {
    console.log(formik.values)
  }, [formik.values])

  const valid = Object.keys(formik.touched).length && formik.isValid

  return (
    <FormikProvider value={formik}>
      <Stack gutter="8" align="stretch">
        <AccessLevelSelector
          value={formik.values.accessLevel}
          onChange={formik.handleChange}
        />
        {formFields.map(({ name, label, component: Component }) => (
          <Component key={name} label={label} name={name} />
        ))}
        <Stack direction="row" justify="flex-end">
          <Button disabled={!valid}>
            Save and start connecting the Platforms
          </Button>
        </Stack>
      </Stack>
    </FormikProvider>
  )
})

export default CompanySetup
