import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import Modal from "@components/ui/Modal/Modal"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Loader from "@components/ui/Loader/Loader"
import { useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"

import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { MetaAccountEntity } from "@store/account-linking/account-linking"
import { capitalizeFirstLetter } from "@utils/stringUtils"
import styles from "./LinkFacebookAccountModal.module.sass"

type EditMemberModalProps = {
  isOpen: boolean
  onClose: () => void
  accessToken: string
  accountId: number
}

const LinkFacebookAccountModal: React.FC<EditMemberModalProps> = observer(
  ({ isOpen, onClose, accessToken, accountId }) => {
    const alert = useAlert()
    const {
      accountLinkingStore: {
        getMetaAccountList,
        metaAccountsList,
        linkMetaAccount,
        metaAccountsListLoading,
        customerError,
      },
    } = useStore()

    const [selected, setSelected] = useState<MetaAccountEntity>()

    const onLink = async () => {
      if (!selected) return
      await linkMetaAccount(accountId, selected).then((res) => {
        if (res) {
          alert.error(<AlertMessage title="Linking failed" description={res} />)
        } else {
          alert.success("Linking successful")
          onClose()
        }
      })
    }

    useEffect(() => {
      getMetaAccountList(accessToken)
    }, [accessToken])

    return (
      <Modal isOpen={isOpen} onClose={onClose} hideHeader>
        <section>
          <Stack gutter="24" align="stretch" className={styles.root}>
            <Typography type="h2">
              Select Meta accounts
              <Loader isLoading={metaAccountsListLoading} />
            </Typography>

            {customerError && (
              <Typography className={styles.error} color="red">
                {capitalizeFirstLetter(customerError)}
              </Typography>
            )}

            <div>
              {metaAccountsList?.length
                ? metaAccountsList?.map((item) => (
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      onClick={() => setSelected(item)}
                      key={item.id}
                    >
                      <CheckBox checked={item.id === selected?.id} />
                      <Typography type="h4">{item.name}</Typography>
                    </Stack>
                  ))
                : null}
            </div>
            {!metaAccountsList?.length && !metaAccountsListLoading ? (
              <Typography type="body2">No accounts found</Typography>
            ) : (
              ""
            )}

            {/* footer */}
            <Stack direction="row" justify="flex-end">
              <Button
                color="secondary"
                onClick={onClose}
                disabled={metaAccountsListLoading}
              >
                Cancel
              </Button>
              <Button onClick={onLink} disabled={metaAccountsListLoading}>
                Link
              </Button>
            </Stack>
          </Stack>
        </section>
      </Modal>
    )
  }
)

export default LinkFacebookAccountModal
