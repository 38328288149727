import React, { useState, useEffect } from "react"
import { ScrollSync } from "react-scroll-sync"
import clsx from "clsx"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { observer } from "mobx-react-lite"
import { FixedSizeList as List } from "react-window"
import type { ListChildComponentProps } from "react-window"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import {
  MetricNameTypeV1,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import useLocalPagination from "@framework/hooks/useLocalPagination"
import { useStore } from "@root/store"
import { calculateTotalMetrics } from "@framework/constants/metrics"
import Header from "./Header"
import Row from "./Row"
import { ColumnMapper } from "./types"
import Footer from "./Footer"

import styles from "./Table.module.scss"

const SINGLE_ROW_HEIGHT = 48
const ITEMS_PER_PAGE = 20
const STATIC_BEFORE = 1

export interface SortState {
  column: string
  direction: "asc" | "desc"
}

interface TableProps<T extends object> {
  className?: string
  mapper: ColumnMapper<T>[]
  data: AnalyticsCard[]
  metricsTrend?: MetricsTrendType
  lastUpdate?: number
  onRowClick?: (item: AnalyticsCard) => void
}

const Table = observer(
  <T extends object>({
    className,
    mapper,
    data,
    lastUpdate,
    metricsTrend,
    onRowClick,
  }: TableProps<T>) => {
    const {
      analyticsFiltersStore: { allFilters },
    } = useStore()
    const infiniteLoaderRef = React.useRef<InfiniteLoader>(null)
    const hasMountedRef = React.useRef(false)
    const [sortState, setSortState] = useState<SortState>({
      column: "",
      direction: "asc",
    })
    const [total, setTotal] = useState<Record<MetricNameTypeV1, MetricType>>({
      AOV: { Value: 0 },
      Clicks: { Value: 0 },
      Impressions: { Value: 0 },
      Conversions: { Value: 0 },
      CostConv: { Value: 0 },
      ConversionRate: { Value: 0 },
      Revenue: { Value: 0 },
      Ctr: { Value: 0 },
      AverageCpc: { Value: 0 },
      Cpm: { Value: 0 },
      Roas: { Value: 0 },
      Cost: { Value: 0 },
      Thumbstop: { Value: 0 },
      Holdplay: { Value: 0 },
    })

    const handleLoadMore = async (startIndex: number, stopIndex: number) => {
      // TODO add loader if needed
    }

    const getSortedData = () => {
      if (!sortState.column) return data || []

      return [...data].sort((a, b) => {
        const aMetric = a[sortState.column] as MetricType
        const bMetric = b[sortState.column] as MetricType
        if (aMetric.Value < bMetric.Value)
          return sortState.direction === "asc" ? -1 : 1
        if (aMetric.Value > bMetric.Value)
          return sortState.direction === "asc" ? 1 : -1
        return 0
      })
    }

    const {
      data: paginatedData,
      next,
      reset: resetPagination,
    } = useLocalPagination<AnalyticsCard>(getSortedData(), ITEMS_PER_PAGE)
    const isRowLoaded = (idx: number) => !!paginatedData?.[idx]

    const RowItem = ({ index, style }: ListChildComponentProps) => {
      const item = paginatedData?.[index]
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          style={style}
          className={styles.row}
          onClick={() => onRowClick && onRowClick(item)}
        >
          <Row
            metricsTrend={metricsTrend}
            data={item}
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
          />
        </div>
      )
    }

    const handleSort = (column: string) => {
      setSortState((prevState) => {
        const isSameColumn = prevState.column === column
        const nextDirection =
          isSameColumn && prevState.direction === "asc" ? "desc" : "asc"
        return { column, direction: nextDirection }
      })
    }

    useEffect(() => {
      if (!data) return
      const res: Record<MetricNameTypeV1, MetricType> =
        calculateTotalMetrics(data)
      setTotal(res)
    }, [data, allFilters])

    useEffect(() => {
      if (hasMountedRef.current && infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true)
      }
      hasMountedRef.current = true
    }, [lastUpdate])

    useEffect(() => {
      if (data.length) resetPagination()
    }, [sortState, data, resetPagination])

    return (
      <ScrollSync>
        <div className={clsx(styles.root, className)}>
          <Header
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
            sortState={sortState}
            onSort={handleSort}
          />
          <Footer
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
            data={total}
          />

          <div className={clsx(styles.body)}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isRowLoaded}
              itemCount={data.length}
              loadMoreItems={next}
            >
              {({ onItemsRendered, ref }) => (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      className={styles.list}
                      height={height}
                      width={width}
                      itemCount={paginatedData.length}
                      itemSize={SINGLE_ROW_HEIGHT}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      overscanCount={3}
                    >
                      {RowItem}
                    </List>
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        </div>
      </ScrollSync>
    )
  }
)

export default Table
