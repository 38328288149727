import React, { Dispatch, SetStateAction } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import { ADSAnalyticsResponse } from "@services/creative-analytics"
import { MetricsTrendType } from "@framework/types/metrics"
import {
  ComponentDescriptor,
  FCProps,
  NullableRecord,
} from "@framework/types/types"
import RowGroup from "@pages/MetaAdsChannel/components/Table/RowGroup"
import SimpleTextCell from "@pages/MetaAdsChannel/components/Table/TableCell/SimpleTextCell"
import {
  ColumnMapper,
  GetValueCallbackType,
  RenderCallbackType,
} from "@pages/MetaAdsChannel/components/Table/types"

import { useAlert } from "react-alert"
import styles from "./CompareTableRow.module.scss"

export const makeDescriptor = <
  T extends React.FC<any>,
  N = NullableRecord<FCProps<T>>
>(
  Component: T,
  props: N = {} as N
): ComponentDescriptor<T, N> => ({ Component, defaultProps: props })

export const DefaultCellDescriptor = makeDescriptor(SimpleTextCell)

const MAX_ADS_SELECTED = 30

interface RowProps<T extends object = any> {
  staticBefore?: number
  mapper: ColumnMapper<T>[]
  data: ADSAnalyticsResponse["data"]["Summary"]
  metricsTrend: MetricsTrendType
  onClick?: () => void
  defaultRenderCallback?: RenderCallbackType<T>
  defaultGetValueCallback?: GetValueCallbackType<T>
  selectedCheckboxes: string[]
  setSelectedCheckboxes: Dispatch<SetStateAction<string[]>>
}

const CompareTableRow: React.FC<any> = observer(
  <T extends object = any>({
    metricsTrend,
    mapper,
    data,
    staticBefore = 0,
    onClick,
    selectedCheckboxes,
    setSelectedCheckboxes,
  }: RowProps<T>) => {
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")
    const alert = useAlert()
    const rowId = toJS(data.Id)
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        if (selectedCheckboxes.length >= MAX_ADS_SELECTED) {
          alert.show("You can select up to 30 MAX_ADS_SELECTED ads")
          return
        }
        setSelectedCheckboxes((prev) => [...prev, rowId as unknown as string])
      } else {
        setSelectedCheckboxes((prev) =>
          prev.filter((id) => id !== (rowId as unknown as string))
        )
      }
    }

    const staticMapper = mapper.slice(0, staticBefore)
    const mainMapper = mapper.slice(staticBefore, mapper.length)

    const renderDataCell = (item: ColumnMapper<T>) => {
      if (!data) return <Skeleton count={2} />
      return <Cell rowMapper={item} data={data} metricsTrend={metricsTrend} />
    }

    const renderCell = (item: ColumnMapper<T>) => (
      <div className={clsx(styles.cell, styles.bodyCell)}>
        {renderDataCell(item)}
      </div>
    )

    return (
      <div className={styles.row}>
        <CheckBox
          className={styles.tableCheckbox}
          checked={selectedCheckboxes.includes(rowId as unknown as string)}
          onChange={handleCheckboxChange}
        />
        <RowGroup
          name="static"
          mapper={staticMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderCell}
          onClick={onClick}
          key="static"
        />
        <RowGroup
          name="main"
          mapper={mainMapper}
          className={styles.group}
          renderCell={renderCell}
          withShadow
          key="main"
        />
      </div>
    )
  }
)

export interface CellProps<T> {
  rowMapper: ColumnMapper<T>
  data: ADSAnalyticsResponse["data"]["Summary"]
  metricsTrend?: MetricsTrendType
}

export const Cell = observer(
  <T,>({ rowMapper, data, metricsTrend }: CellProps<T>) => {
    const props = {
      name: rowMapper.name,
      data,
      metricsTrend,
    }
    const { Component, defaultProps } = rowMapper.view ?? DefaultCellDescriptor
    return <Component {...defaultProps} {...props} />
  }
)

export default CompareTableRow
