import React, { FC, useState, useMemo, Dispatch, SetStateAction } from "react"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import {
  AdSet,
  AdsItem,
  MetaAdsItem,
  RequestChanges,
} from "@framework/types/account"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"

import styles from "./CheckboxDropdown.module.scss"

interface CheckboxDropdownProps {
  data: MetaAdsItem[]
  searchQuery: string
  requestChanges: RequestChanges
  setRequestChanges: Dispatch<SetStateAction<RequestChanges>>
}

const CheckboxDropdown: FC<CheckboxDropdownProps> = ({
  data,
  searchQuery,
  requestChanges,
  setRequestChanges,
}) => {
  const [expandedCampaigns, setExpandedCampaigns] = useState<string[]>([])
  const [expandedAdSets, setExpandedAdSets] = useState<string[]>([])

  const {
    productFeedStore: { list },
  } = useStore()

  const isExpanded = (id: string, type: "campaign" | "adset") =>
    type === "campaign"
      ? expandedCampaigns.includes(id)
      : expandedAdSets.includes(id)

  const toggleExpand = (id: string, type: "campaign" | "adset") => {
    if (type === "campaign") {
      setExpandedCampaigns((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      )
    } else {
      setExpandedAdSets((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      )
    }
  }
  const isInAllProducts = (adId: string): boolean => {
    const selectedProducts = Array.from(list.selected.data)
    if (selectedProducts.length === 0) return false

    return selectedProducts.every((productId) => {
      const product = list.productById(productId)
      return product?.connectedAds?.some((ad) => ad.id === adId)
    })
  }

  const isInSomeProducts = (adId: string): boolean => {
    const selectedProducts = Array.from(list.selected.data)
    if (selectedProducts.length === 0) return false

    return selectedProducts.some((productId) => {
      const product = list.productById(productId)
      return product?.connectedAds?.some((ad) => ad.id === adId)
    })
  }

  const isDisabled = (item: AdsItem): boolean =>
    isInSomeProducts(item.id) && !isInAllProducts(item.id)

  const isAdSetDisabled = (ads: AdsItem[]): boolean =>
    ads.length > 0 && ads.every((ad) => isDisabled(ad))

  const isCampaignDisabled = (adSets: AdSet[]): boolean => {
    const allAds = adSets.flatMap((adSet) => adSet.Ads)
    return allAds.length > 0 && allAds.every((ad) => isDisabled(ad))
  }

  const isChecked = (item: AdsItem): boolean => {
    const inAllProducts = isInAllProducts(item.id)
    const inRemove = requestChanges.remove.some((ad) => ad.id === item.id)
    const inAdd = requestChanges.add.some((ad) => ad.id === item.id)

    if (inAllProducts && !inRemove) return true
    if (inAdd) return true

    return false
  }

  const getParentCheckboxState = (ads: AdsItem[]) => {
    const enabledAds = ads.filter((ad) => !isDisabled(ad))
    if (enabledAds.length === 0) return { checked: false, partial: false }

    const checkedStates = enabledAds.map((ad) => isChecked(ad))
    const allChecked = checkedStates.every((state) => state)
    const someChecked = checkedStates.some((state) => state)

    return {
      checked: someChecked,
      partial: someChecked && !allChecked,
    }
  }

  const toggleCheck = (item: AdsItem) => {
    const currentlyChecked = isChecked(item)
    const inAllProducts = isInAllProducts(item.id)

    setRequestChanges((prev) => {
      const newChanges = {
        remove: [...prev.remove],
        add: [...prev.add],
      }

      if (currentlyChecked) {
        if (inAllProducts) {
          newChanges.remove.push({
            id: item.id,
            name: item.name,
            status: item.status,
          })
        } else {
          newChanges.add = newChanges.add.filter((ad) => ad.id !== item.id)
        }
      } else if (inAllProducts) {
        newChanges.remove = newChanges.remove.filter((ad) => ad.id !== item.id)
      } else {
        newChanges.add.push({
          id: item.id,
          name: item.name,
          status: item.status,
        })
      }
      return newChanges
    })
  }

  const toggleParentCheck = (ads: AdsItem[]) => {
    const { checked } = getParentCheckboxState(ads)

    setRequestChanges((prev) => {
      const newChanges = { ...prev }

      if (checked) {
        ads.forEach((ad) => {
          if (isInAllProducts(ad.id)) {
            if (!newChanges.remove.some((item) => item.id === ad.id)) {
              newChanges.remove.push({
                id: ad.id,
                name: ad.name,
                status: ad.status,
              })
            }
          }
          newChanges.add = newChanges.add.filter((item) => item.id !== ad.id)
        })
      } else {
        ads.forEach((ad) => {
          if (isInAllProducts(ad.id)) {
            newChanges.remove = newChanges.remove.filter(
              (item) => item.id !== ad.id
            )
          } else if (!newChanges.add.some((item) => item.id === ad.id)) {
            newChanges.add.push({ id: ad.id, name: ad.name, status: ad.status })
          }
        })
      }
      return newChanges
    })
  }

  const searchResults = useMemo(() => {
    if (!searchQuery) return null

    const results: Array<{
      id: string
      name: string
      type: "campaign" | "adset" | "ad"
      parentName?: string
      item: MetaAdsItem | AdSet | AdsItem
    }> = []

    const lowerQuery = searchQuery.toLowerCase()

    data.forEach((campaign) => {
      if (campaign.name.toLowerCase().includes(lowerQuery)) {
        results.push({
          id: campaign.id,
          name: campaign.name,
          type: "campaign",
          item: campaign,
        })
      }

      campaign.AdSets.forEach((adSet) => {
        if (adSet.name.toLowerCase().includes(lowerQuery)) {
          results.push({
            id: adSet.id,
            name: adSet.name,
            type: "adset",
            parentName: campaign.name,
            item: adSet,
          })
        }

        adSet.Ads.forEach((ad) => {
          if (ad.name.toLowerCase().includes(lowerQuery)) {
            results.push({
              id: ad.id,
              name: ad.name,
              type: "ad",
              parentName: `${campaign.name} / ${adSet.name}`,
              item: ad,
            })
          }
        })
      })
    })

    return results
  }, [data, searchQuery])

  if (searchQuery && searchResults) {
    return (
      <div className={styles.checkboxContainer}>
        {searchResults.map(({ id, name, type, parentName, item }) => (
          <Stack direction="column" gutter="0" key={id}>
            {type === "ad" && (
              <InlineLabel type="h4" text={name}>
                <CheckBox
                  name={id}
                  checked={isChecked(item as AdsItem)}
                  onChange={() => toggleCheck(item as AdsItem)}
                  disabled={isDisabled(item as AdsItem)}
                />
              </InlineLabel>
            )}
            {parentName && (
              <Typography
                type="h5"
                color="black40Color"
                style={{ marginBottom: "8px" }}
              >
                {parentName}
              </Typography>
            )}
          </Stack>
        ))}
      </div>
    )
  }

  return (
    <Stack direction="column" gutter="12" className={styles.checkboxContainer}>
      {data.map((campaign) => (
        <Stack direction="column" gutter="12" key={campaign.id}>
          <Stack direction="row" align="center" style={{ marginLeft: "10px" }}>
            {campaign.AdSets.length > 0 && (
              <Icon
                name="arrow-right"
                rotateAngle={isExpanded(campaign.id, "campaign") ? 90 : 0}
                onClick={() => toggleExpand(campaign.id, "campaign")}
                className="mr-2 cursor-pointer"
              />
            )}
            <InlineLabel type="h4" text={campaign.name}>
              <CheckBox
                name={campaign.id}
                {...getParentCheckboxState(
                  campaign.AdSets.flatMap((adSet) => adSet.Ads)
                )}
                onChange={() =>
                  toggleParentCheck(
                    campaign.AdSets.flatMap((adSet) => adSet.Ads)
                  )
                }
                disabled={isCampaignDisabled(campaign.AdSets)}
              />
            </InlineLabel>
          </Stack>

          {isExpanded(campaign.id, "campaign") &&
            campaign.AdSets.map((adSet) => (
              <Stack direction="column" gutter="12" key={adSet.id}>
                <Stack
                  direction="row"
                  align="center"
                  style={{ marginLeft: "24px" }}
                >
                  {adSet.Ads.length > 0 && (
                    <Icon
                      name="arrow-right"
                      rotateAngle={isExpanded(adSet.id, "adset") ? 90 : 0}
                      onClick={() => toggleExpand(adSet.id, "adset")}
                      className="mr-2 cursor-pointer"
                    />
                  )}
                  <InlineLabel type="h4" text={adSet.name}>
                    <CheckBox
                      name={adSet.id}
                      {...getParentCheckboxState(adSet.Ads)}
                      onChange={() => toggleParentCheck(adSet.Ads)}
                      disabled={isAdSetDisabled(adSet.Ads)}
                    />
                  </InlineLabel>
                </Stack>

                {isExpanded(adSet.id, "adset") &&
                  adSet.Ads.map((ad) => (
                    <Stack
                      key={ad.id}
                      direction="row"
                      align="center"
                      style={{ marginLeft: "55px" }}
                    >
                      <InlineLabel type="h4" text={ad.name}>
                        <CheckBox
                          name={ad.id}
                          checked={isChecked(ad)}
                          onChange={() => toggleCheck(ad)}
                          disabled={isDisabled(ad)}
                        />
                      </InlineLabel>
                    </Stack>
                  ))}
              </Stack>
            ))}
        </Stack>
      ))}
    </Stack>
  )
}

export default CheckboxDropdown
