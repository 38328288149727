import React, { useRef, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import Image from "@components/ui/Image/Image"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import { redirectToOrigin } from "../utils"

import styles from "./MediaSection.module.sass"

interface MediaSectionProps {
  data: AnalyticsCard
  handleDuration: (duration: number) => void
}

export const MediaSection: React.FC<MediaSectionProps> = observer(
  ({ data, handleDuration }) => {
    const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true)
    const handleOnIFrameLoad = () => {
      setIsIframeLoading(false)
    }
    const videoRef = useRef<HTMLVideoElement | null>(null)

    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        handleDuration(videoRef.current.duration)
      }
    }

    return (
      <Box className={clsx(styles.root)} elevation={1}>
        <div className={styles.mediaContainer}>
          {data?.Type === "IMAGE" ? (
            <Image
              src={data.ThumbnailURL}
              className={styles.image}
              alt="Preview"
            />
          ) : data?.Type === "VIDEO" ? (
            <div className={styles.videoWrap}>
              {data.VideoSource === "facebook_plugin" && isIframeLoading && (
                <div className={styles.videoLoader}>
                  <CircleLoader />
                </div>
              )}
              {data.VideoSource === "facebook_plugin" ? (
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                <iframe
                  src={data?.VideoURL}
                  className={styles.video}
                  onLoad={handleOnIFrameLoad}
                />
              ) : null}
              {data.VideoSource === "instagram_raw" ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  ref={videoRef}
                  onLoadedMetadata={handleLoadedMetadata}
                  className={styles.video}
                  src={data?.VideoURL}
                  controls
                />
              ) : null}
            </div>
          ) : null}

          <Typography
            className={styles.views}
            type="body1"
            color="black03Color"
            title="People are watching"
          >
            <Icon
              name="eye"
              onClick={(e) => {
                redirectToOrigin(e, data.PageURL)
              }}
            />
            {/* {numberWithCommas(data.totalViews ?? 0)} */}
          </Typography>
        </div>
      </Box>
    )
  }
)

export default MediaSection
