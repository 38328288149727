import React from "react"
import styles from "./Charts.module.scss"

const BgStripes = () => (
  <div className={styles.BgStripesContainer}>
    {[...Array(5)].map((_, index) => (
      <div
        className={styles.item}
        style={{
          top: `${index * 25}%`,
        }}
      />
    ))}
  </div>
)

export default BgStripes
