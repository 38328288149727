import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"

import styles from "./PerformanceGoalCard.module.sass"

type Status = "winner" | "loser" | "default"
export type FilterType = "winners" | "losers" | "scaling" | "new"
export interface PerformanceGoalCardProps {
  title: string
  description: string
  total: number
  filterType: FilterType
  active?: boolean
  status?: Status
  onClick?: React.MouseEventHandler
  onEditClick?: React.MouseEventHandler
}

export const PerformanceGoalCard: React.FC<PerformanceGoalCardProps> = ({
  status = "default",
  title,
  description,
  total = 0,
  active = false,
  onClick,
  onEditClick,
}) => (
  <Box
    onClick={onClick}
    elevation={0}
    className={clsx(styles.root, {
      [styles.active]: active,
    })}
  >
    <div className={styles.header}>
      <Typography type="h1" weight="bold">
        {total}
      </Typography>

      {onEditClick ? (
        <IconButton
          variant="contained"
          color="secondary"
          onClick={(e) => {
            onEditClick?.(e)
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Icon name="pen" />
        </IconButton>
      ) : null}
    </div>

    <div className={styles.body}>
      <Stack gutter="4" direction="row" align="center">
        {getIcon(status)}
        <Typography type="h4" color="black100Color" weight="bold" inline>
          {title}
        </Typography>
      </Stack>

      <Typography type="body1" color="black70Color">
        {description}
      </Typography>
    </div>
  </Box>
)

export default PerformanceGoalCard

const getIcon = (status: Status) => {
  if (status === "winner")
    return (
      <>
        <Typography type="h1" color="lightGreen" inline>
          <Icon name="vip-crown" />
        </Typography>
      </>
    )

  if (status === "loser")
    return (
      <Typography type="h1" color="error100Color">
        <Icon name="long-arrow-right" rotateAngle={45} />
      </Typography>
    )

  return null
}
