import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ScrollSync } from "react-scroll-sync"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { List, ListRowProps } from "react-virtualized"
import { useStore } from "@store/index"
import { ColumnMapper } from "@pages/MetaAdsChannel/components/Table/types"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import {
  MetricNameTypeV1,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import { ADSAnalyticsResponse } from "@services/creative-analytics"
import Footer from "@pages/MetaAdsChannel/components/Table/Footer"
import { calculateTotalMetrics } from "@framework/constants/metrics"
import CompareTableRow from "./components/CompareTableRow/CompareTableRow"
import CompareTableHeader from "./components/CompareTableRow/CompareTableHeader"

import styles from "./CompareTable.module.scss"

const SINGLE_ROW_HEIGHT = 48

const STATIC_BEFORE = 1

export interface SortState {
  column: string
  direction: "asc" | "desc"
}

interface TableProps<T extends object> {
  className?: string
  mapper: ColumnMapper<T>[]
  data: AnalyticsCard[]
  metricsTrend?: MetricsTrendType
  summary: ADSAnalyticsResponse["data"]["Summary"]
  lastUpdate?: number
  onRowClick?: (item: AnalyticsCard) => void
  selectedCheckboxes: string[]
  setSelectedCheckboxes: Dispatch<SetStateAction<string[]>>
}

const Table = observer(
  <T extends object>({
    className,
    mapper,
    data,
    summary,
    lastUpdate,
    metricsTrend,
    onRowClick,
    selectedCheckboxes,
    setSelectedCheckboxes,
  }: TableProps<T>) => {
    const {
      analyticsFiltersStore: { allFilters },
    } = useStore()
    const infiniteLoaderRef = React.useRef<InfiniteLoader>(null)
    const hasMountedRef = React.useRef(false)
    const [sortState, setSortState] = useState<SortState>({
      column: "",
      direction: "asc",
    })
    const [sortedData, setSortedData] = useState<AnalyticsCard[]>([])

    const [total, setTotal] = useState<Record<MetricNameTypeV1, MetricType>>({
      AOV: { Value: 0 },
      Clicks: { Value: 0 },
      Impressions: { Value: 0 },
      Conversions: { Value: 0 },
      CostConv: { Value: 0 },
      ConversionRate: { Value: 0 },
      Revenue: { Value: 0 },
      Ctr: { Value: 0 },
      AverageCpc: { Value: 0 },
      Cpm: { Value: 0 },
      Roas: { Value: 0 },
      Cost: { Value: 0 },
      Thumbstop: { Value: 0 },
      Holdplay: { Value: 0 },
    })

    const isRowLoaded = (idx: number) => !!sortedData?.[idx]

    const renderRow = ({ index, style, key }: ListRowProps) => {
      const item = sortedData?.[index]
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={key}
          style={style}
          className={styles.row}
          onClick={() => onRowClick && onRowClick(item)}
        >
          <CompareTableRow
            setSelectedCheckboxes={setSelectedCheckboxes}
            selectedCheckboxes={selectedCheckboxes}
            metricsTrend={metricsTrend}
            data={item}
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
          />
        </div>
      )
    }

    const handleSort = (column: string) => {
      setSortState((prevState) => {
        const isSameColumn = prevState.column === column
        const nextDirection =
          isSameColumn && prevState.direction === "asc" ? "desc" : "asc"
        return { column, direction: nextDirection }
      })
    }

    useEffect(() => {
      if (!data) return
      const res: Record<MetricNameTypeV1, MetricType> =
        calculateTotalMetrics(data)
      setTotal(res)
    }, [data, allFilters])

    useEffect(() => {
      if (hasMountedRef.current && infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true)
      }
      hasMountedRef.current = true
    }, [lastUpdate])

    useEffect(() => {
      let res: AnalyticsCard[] = []
      if (!sortState.column && !data) return
      if (!sortState.column && data) res = [...data]
      if (sortState.column && data) {
        res = [...data].sort((a, b) => {
          const aMetric = a[sortState.column] as MetricType
          const bMetric = b[sortState.column] as MetricType
          if (aMetric.Value < bMetric.Value)
            return sortState.direction === "asc" ? -1 : 1
          if (aMetric.Value > bMetric.Value)
            return sortState.direction === "asc" ? 1 : -1
          return 0
        })
      }
      setSortedData(res)
    }, [sortState, data])

    return (
      <ScrollSync>
        <div className={clsx(styles.root, className)}>
          <CompareTableHeader
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
            sortState={sortState}
            onSort={handleSort}
          />
          <Footer
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={clsx(styles.headerContainer, styles.footerPadding)}
            data={total}
          />
          <div className={clsx(styles.body)}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isRowLoaded}
              itemCount={sortedData.length}
              loadMoreItems={() => {
                // TO DO.. loader if needed
              }}
              threshold={40}
              minimumBatchSize={40}
            >
              {({ ref }) => (
                <AutoSizer>
                  {(size) => (
                    <List
                      className={styles.list}
                      overscanCount={3}
                      height={size.height}
                      width={size.width}
                      rowCount={sortedData.length}
                      rowHeight={SINGLE_ROW_HEIGHT}
                      rowRenderer={renderRow}
                      ref={ref}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        </div>
      </ScrollSync>
    )
  }
)

export default Table
