import { useCallback, useMemo, useState } from "react"

const useLocalPagination = <T,>(
  allData: Array<T> = [],
  itemsPerPage: number = 10
) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [accumulatedData, setAccumulatedData] = useState<Array<T>>([])

  const totalPages = useMemo(
    () => Math.ceil(allData.length / itemsPerPage),
    [allData.length, itemsPerPage]
  )

  const currentData = useMemo(() => {
    const endIndex = currentPage * itemsPerPage
    const newPageData = allData.slice(0, endIndex)

    if (newPageData.length > accumulatedData.length) {
      setAccumulatedData(newPageData)
    }

    return accumulatedData
  }, [allData, currentPage, itemsPerPage, accumulatedData])

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1)
    }
  }

  const reset = useCallback(() => {
    setCurrentPage(1)
    setAccumulatedData([])
  }, [])

  return {
    data: currentData,
    currentPage,
    totalPages,
    next,
    hasNext: currentPage < totalPages,
    reset,
  }
}

export default useLocalPagination
