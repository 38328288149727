import { AnalyticsFilter } from "@framework/types/creativeAnalytics"
import { MetricDescription } from "@framework/types/metrics"
import { CAFilters, initialCAFilters } from "@store/creative-analytics/utils"
import { useStore } from "@store/index"
import { useCallback, useEffect } from "react"

export type CAFilterNames = "Summary" | "General" | "Winners" | string

const useCAFilters = (
  tabName: CAFilterNames
): [
  CAFilters,
  (filter: CAFilters) => void,
  {
    setFilterString: (filterString: string[]) => void
    setAppliedFilters: (appliedFilters: AnalyticsFilter[][]) => void
  },
  (metrics: MetricDescription[]) => void,
  () => void
] => {
  const { caFiltersStore } = useStore()

  const {
    allFilters,
    setFilter: setFilterByName,
    setFilterString: setFilterStringByName,
    setAppliedFilters: setAppliedFiltersByName,
    setMetrics: setMetricsByName,
    setStateInited: setStateInitedByName,
  } = caFiltersStore

  const setFilter = useCallback(
    (filter: CAFilters) => setFilterByName(tabName, filter),
    [setFilterByName, tabName]
  )

  const setFilterString = (filterString: string[]) =>
    setFilterStringByName(tabName, filterString)

  const setAppliedFilters = (appliedFilters: AnalyticsFilter[][]) =>
    setAppliedFiltersByName(tabName, appliedFilters)

  const setMetrics = (metrics: MetricDescription[]) =>
    setMetricsByName(tabName, metrics)

  const setStateInited = () => {
    setStateInitedByName(tabName)
    console.log(tabName)
  }

  useEffect(() => {
    if (!allFilters[tabName]) {
      setFilter(initialCAFilters)
    }
  }, [allFilters, setFilter, tabName])

  return [
    allFilters[tabName] || initialCAFilters,
    setFilter,
    { setFilterString, setAppliedFilters },
    setMetrics,
    setStateInited,
  ]
}

export default useCAFilters
