import { emailValidator } from "@utils/validators"
import * as yup from "yup"

export type AccesLevel = "Client" | "Agency"

export interface CompanyFormValues {
  accessLevel: AccesLevel
  clientName: string
  clientEmail: string
  contactName: string
  contactPhone: string
  website: string
  state: string
  city: string
  postal: string
  street: string
  industry: string
  yearlyAdBudget: string
  accountName: string
}

export const initialValues: CompanyFormValues = {
  accessLevel: "Client",
  clientName: "",
  clientEmail: "",
  contactName: "",
  contactPhone: "1",
  website: "",
  state: "",
  city: "",
  postal: "",
  street: "",
  industry: "",
  yearlyAdBudget: "",
  accountName: "",
}

export const validationSchema = yup.object({
  accessLevel: yup.string().required("Acces level is required"),
  clientName: yup
    .string()
    .required("Client name is required")
    .max(255, "up to 255 symbols"),
  clientEmail: emailValidator
    .email("Invalid email format")
    .required("Client email is required")
    .max(255, "up to 255 symbols"),
  contactName: yup
    .string()
    .required("Contact name is required")
    .max(255, "up to 255 symbols"),

  contactPhone: yup
    .string()
    .required("Contact phone is required")
    .min(11, "Write the phone number in full"),
  website: yup.string().url("Invalid URL format").max(255, "up to 255 symbols"),
  state: yup.string().max(255, "up to 255 symbols"),
  city: yup.string().max(255, "up to 255 symbols"),
  postal: yup
    .string()
    .min(5, "write postel is USA format")
    .max(5, "write postel is USA format"),
  street: yup.string().max(255, "up to 255 symbols"),
  industry: yup.string().max(255, "up to 255 symbols"),
  yearlyAdBudget: yup
    .string()
    .min(0, "Budget cannot be negative")
    .max(255, "up to 255 symbols"),
  accountName: yup
    .string()
    .required("Account name is required")
    .max(255, "up to 255 symbols"),
})
