import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { toJS } from "mobx"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import TextField from "@components/ui/TextField/TextField"
import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import {
  ProductMetaAdsConnection,
  RequestChanges,
} from "@framework/types/account"
import accountService from "@services/account.service"
import CheckboxDropdown from "./CheckboxDropdown/CheckboxDropdown"

import styles from "./SideBarMetaLink.module.scss"

interface SideBarMetaLinkProps {
  show: boolean
  onClose: () => void
}

const SideBarMetaLink: FC<SideBarMetaLinkProps> = observer(
  ({ show, onClose }) => {
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [requestChanges, setRequestChanges] = useState<RequestChanges>({
      remove: [],
      add: [],
    })

    const alert = useAlert()

    const {
      accountStore: { accountId },
      productFeedStore: {
        list,
        metaAds: { loadAdsLisst, getAdsList },
      },
    } = useStore()

    useEffect(() => {
      if (accountId) {
        loadAdsLisst(accountId)
      }
    }, [accountId])

    const handleLinkAds = () => {
      const reqBody: ProductMetaAdsConnection = {
        add: [],
        remove: [],
      }
      const ids = requestChanges.remove.map((el) => el.id)

      toJS(list.selected.data).forEach((el) => {
        if (requestChanges.remove.length > 0) {
          reqBody.remove.push({
            productVariantId: Number(el),
            adIds: ids,
          })
        }
        if (requestChanges.add.length > 0) {
          reqBody.add.push({
            productVariantId: Number(el),
            ads: [...requestChanges.add],
          })
        }
      })
      if (accountId) {
        accountService.setMetaAdsList(accountId, reqBody).then((res) => {
          if (res.status === 200) {
            alert.success("Changes is saved")
            list.reloadProductFeed(true)
          }
        })
      }
    }

    const handleClose = () => {
      onClose()
      setRequestChanges({
        remove: [],
        add: [],
      })
      setSearchQuery("")
    }

    const handleSubmmit = () => {
      handleLinkAds()
      onClose()
      list.selected.selectAll()
    }

    return (
      <SidebarContainer
        className={styles.root}
        isOpen={show}
        onClose={handleClose}
      >
        <div className={styles.containerPading}>
          <Stack direction="column" gutter="24">
            <Typography type="h2" weight="bold">
              Link to Meta Ads
            </Typography>
            <Typography type="h2">
              Select Meta Ads you want to connect products to
            </Typography>
          </Stack>
          <TextField
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.currentTarget.value)
            }}
            className={styles.input}
            before={<Icon name="search" />}
            placeholder="Search"
          />
        </div>

        <CheckboxDropdown
          data={getAdsList}
          searchQuery={searchQuery}
          requestChanges={requestChanges}
          setRequestChanges={setRequestChanges}
        />

        <Templates.Header
          className={styles.footer}
          offset="none"
          right={
            <Stack direction="row">
              <Button color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleSubmmit}>
                Apply
              </Button>
            </Stack>
          }
        />
      </SidebarContainer>
    )
  }
)

export default SideBarMetaLink
