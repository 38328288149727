import { PerformanceGoalCardProps } from "@pages/MetaAdsChannel/components/PerformanceGoalCard/PerformanceGoalCard"

const performanceGoalOptions: Omit<PerformanceGoalCardProps, "total">[] = [
  {
    title: "Winners",
    description: "Creatives, that reached your goal within the time period",
    status: "winner",
    filterType: "winners",
  },
  {
    title: "Losers",
    description:
      "Creatives, that have not reached your goal within the time period",
    status: "loser",
    filterType: "losers",
  },
  {
    title: "Scaling",
    description: "Creatives, that spend more of your goal",
    filterType: "scaling",
  },
  {
    title: "New",
    description: "Creatives, that launched within the specific period",
    filterType: "new",
  },
]

export default performanceGoalOptions
